export const data = [
	{
		icon: "/images/about/Mission.png",
		title: "Mission",
		description:
			"THOUGHTZ has a simple, straightforward goal of connecting and harnessing the power of the collective minds of people and their thoughts in real-time.",
	},
	{
		icon: "/images/about/Vision.png",
		title: "Vision",
		description:
			"The far-fetched idea of THOUGHTZ is to allow individuals to quickly connect and accomplish things. Our vision is to foster, enable, and cultivate connections amongst minds all around the world.",
	},
	{
		icon: "/images/about/Values.png",
		title: "Values",
		description: [
			"Openness",
			"Be Curious",
			"Pure Fun",
			"Collaborate",
			"Fuel Creativity",
		],
	},
];
