import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const FAQLayout= styled.section`

    margin-bottom: -3.5rem;

    .textWrapper {
        padding: 5rem 0 3rem 0;

        .gradientHeading {
            font-weight: bold;
            font-size: 78px;
            background: linear-gradient(to right, rgb(201,40,152), rgb(226,39,144));
            width: fit-content;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            -webkit-background-clip: text;
            line-height: 105px;
            line-height: 100px;
            width: max-content;
        }
    }

    .question {
        font-size: 25px;
        font-weight: 600;
        margin-left: 10px;
        text-align: start;
    }

    .paragraph {
        letter-spacing: 1px;
        color: grey;
        margin-bottom: 10px;
        padding-left: 15px;
    }

    .footer-text {
        margin-top: 3rem;
    }

    @media (max-width: ${ constants.breakpoints.mobile }) {
        .textWrapper {
            padding-bottom: 2rem;

            .gradientHeading {
                font-size: 35px;
                line-height: 45px;
            }
        }

        .question {
            font-size: 20px;
        }

        .footer-text {
            font-size: 12px;
        }
    }
`