import { Fragment } from "react";
import { Text } from "@chakra-ui/react";
import { GoPrimitiveDot } from "react-icons/go";
import { FooterLayout, GradientText, ThinkLayout } from "./styles";
import Marquee from "react-fast-marquee";
import { items, socialsData, footerLinks, pageLinks } from "./data";

// For reference
// https://codesandbox.io/s/react-slick-playground-forked-q4o0w?file=/index.js

const AnimatedElement = () => {
	return (
		<Marquee className="marquee1" gradientWidth="0" speed={40}>
			<ThinkLayout>
				{items.map((item, index) => (
					<Fragment key={index}>
						<Text className="think-text">
							<GradientText gradientColors={item.gradientColors}>
								{item.text}{" "}
							</GradientText>
							<img
								src={item.emoji}
								width="40px"
								className="ms-2"
								alt={item.text}
							/>
							{/* <span>{item.emoji}</span> */}
						</Text>
						{/* <GoPrimitiveDot fill="white" size={24} /> */}
					</Fragment>
				))}
			</ThinkLayout>
		</Marquee>
	);
};

export const Footer = () => {
	return (
		<>
			<FooterLayout>
				<AnimatedElement />
				<div className="w-100 py-5 px-4" style={{ minHeight: "50vh" }}>
					<div className="row w-100">
						<div className="col-md-5 col-12">
							<a href="/">
								<div className="d-flex align-items-center brand-logo">
									<img
										className="logo"
										src="/images/logos/img1.jpg"
										alt="footer-logo"
									/>
									<p
										className="mx-3"
										style={{
											fontSize: "1.75rem",
											fontWeight: "500",
										}}
									>
										Thoughtz
									</p>
								</div>
							</a>
							<div className="mt-5">
								Contact:{" "}
								<a
									href="mailto:hello@thoughtz.ai"
									target="_blank"
								>
									hello@thoughtz.ai
								</a>
							</div>
						</div>
						<div className="col-md-2 col-6 footerLinks">
							<ul>
								{footerLinks.map((link, index) => (
									<li key={index}>
										<a href={link.link}>{link.label}</a>
									</li>
								))}
							</ul>
						</div>
						<div className="col-md-2 col-6 footerLinks">
							<ul>
								{pageLinks.map((link, index) => (
									<li key={index}>
										<a href={link.link} target="_blank">
											{link.label}
										</a>
									</li>
								))}
								<li>
									<a href="/lightpaper.pdf" target="_blank">
										Litepaper
									</a>
								</li>
								<li>
									<a href="/whitepaper.pdf" target="_blank">
										Whitepaper
									</a>
								</li>
							</ul>
						</div>
						<div className="col-md-3 col-12 social-grp">
							<h3
								className="mt-3 mb-4 text-muted"
								style={{ fontSize: "1.3rem" }}
							>
								STAY CONNECTED
							</h3>
							<div className="row px-2">
								{socialsData.map((item, index) => (
									<div className="col-3 p-2 link" key={index}>
										<a
											href={item.link && item.link}
											target="_blank"
											key={index}
											rel="noreferrer"
										>
											<img
												src={item.imageURL}
												width="35px"
												height="35px"
												alt="social-logo"
											/>
										</a>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
				<div className="footerWrapper">
					<div className="footerDetailsWrapper">
						{[
							"© Thoughtz 2021",
							<a href="/terms">Terms and Conditions</a>,
							<a href="/policies">Privacy Policy</a>,
						].map((item, index) => (
							<div key={index} className="footerText">
								{item}
							</div>
						))}
					</div>
					<div>
						<p className="tredmark">
							<a href="https://www.bharg.com/" target="_blank">
								A Bharg Technologies GmbH Company
							</a>
						</p>
					</div>
				</div>
			</FooterLayout>
		</>
	);
};
