import { createGlobalStyle } from "styled-components";
import { constants } from "../constants/constants";

export const GlobalStyles = createGlobalStyle`
    * {
        padding: 0px;
        margin: 0px;
        box-sizing: border-box;
        text-decoration: none;
        outline: none;
        max-width: 100%;


        input {
            &::placeholder {
                color: inherit;
            }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px rgb(7,7,7) inset !important; 
            -webkit-text-fill-color: #fff;
        }

        a {
            &:hover {
                color: inherit;
            }
        }
    }

    html {
        font-size: 16px;
        scroll-behavior: smooth;

        body {
            overflow-x: hidden;
            color: white;
            background: ${constants.theme.background};
            background-color: #000;

            #root {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-family: 'Poppins', sans-serif!important;

                section {
                    width: 100%;
                    min-height: 100vh;
                    max-width: 1400px;
                    padding: 0 1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
        }
    }

    .underline{
		width: 3rem;
		height: 2px;
		background: url("/images/background.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
    }

    .roadmap{
        margin-bottom: 5rem;
        width: 100%;

        @media (max-width: ${constants.breakpoints.mobile}) {
            margin-top: 0;
        }
    }

    .timeline {
        width: 100%;
        height: 100px;
        /* max-width: 80%; */
        /* margin: 0 auto; */
        display: flex;      
        justify-content: center;    
    }

    .timeline-div{
        display: flex;
        align-items: flex-start;
    }

    .timeline .events {
        position: relative;
        background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
        height: 1.5rem;
        width: 100%;
        border-radius: 4px;
        margin: 5em 0;
    }

    .timeline .events ol {
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .timeline .events ul {
        list-style: none;
    }

    .timeline .events ul li {
        display: inline-block;
        width: 16%;
        margin: 0;
        padding: 0;
    }

    .timeline .events ul li a {
        font-family: 'Poppins', sans-serif;
        font-size: 1.25em;
        text-decoration: none;
        position: relative;
        top: -32px;
    }

    .timeline .events ul li a:after {
        content: '';
        position: absolute;
        bottom: -2.5rem;
        left: 50%;
        right: auto;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 3px solid #fff;
        background-color: transparent;
        transition: 0.3s ease;
        transform: translateX(-50%);
        cursor: pointer;
    }

    .timeline .events ul li a:hover::after {
        border: 5px solid #fff;
    }

    .timeline .events ul li a.selected:after {
        /* pos */
    }

    .verti{
        position: absolute;
        margin-left: 2.5rem;

        width: 3px;
        height: 1.5rem;
        background: #fff;
    }

    .semi{
        position: absolute;
        margin-top: 1.5rem;
        margin-left: 2.4rem;
        width: 5px;
        height: 5px;
        background: #fff;
    }
                
    .events-content {
        width: 100%;
        margin: 3rem 0;
        display: flex;      
        justify-content: left;
    }

    .events-content li {
        display: none;
        /* list-style: none; */
    }

    .events-content li.selected {
        padding: .5rem 1rem;
        width: 20%;
        display: initial;
        cursor: pointer;
    }

    .events-content li h2 {
        margin-bottom: .25rem;
        font-family: 'Frank Ruhl Libre', serif;
        font-weight: 500;
        font-size: 3rem;
    }
	
    .sub-heading-1{
		font-size: 2rem !important;
		font-weight: 500;
	}

    .events-content li p {
        line-height: 2;
        /* font-size: 1.2rem; */
    }

    .selected-1{
        padding: 1rem;
        background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
        border-radius: 1rem;
        box-shadow: 0px 0px 10px 3px #333;
    }

    .grad-head{
		margin-top: 1rem;
        font-weight: 600;
        font-size: 5rem;
		background: linear-gradient(to right, rgb(208, 40, 155), rgb(249, 104, 119));
		background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;

        @media (max-width: ${constants.breakpoints.mobile}) {
            font-size: 3rem;
            text-align: center;
        }
	}

`;
