import { Fragment } from "react";
import { SectionParent } from "./styles";
import { useAos } from "../../../hooks/useAos";

import {
	Animator,
	ScrollPage,
	batch,
	FadeIn,
	Sticky,
	ZoomIn,
	MoveOut,
	Fade,
	FadeOut,
} from "react-scroll-motion";

const ZoomInScrollOut = batch(Sticky(), FadeIn(), ZoomIn(), MoveOut(0, -300));

export function Landing() {
	useAos(1000);

	return (
		<Fragment>
			<SectionParent>
				<ScrollPage page={2}>
					<Animator animation={ZoomInScrollOut}>
						<div id="text1" className=" intro-text">
							<h1>Thoughtz is all about</h1>
							<p>connecting and rewarding</p>
						</div>
					</Animator>
					<Animator
						animation={batch(Fade(), Sticky(), MoveOut(0, -50))}
					>
						<img
							style={{
								opacity: "0.3",
							}}
							src="/images/logos/img3.svg"
							alt="logo"
						/>
					</Animator>
				</ScrollPage>
				{/* <ScrollPage page={4}>
					<Animator animation={ZoomInScrollOut}>
						<div id="text2" className=" outro-text">
							<p>Create NFTs to express your thoughts,</p>
							<p>emotions, ideas and concepts</p>
						</div>
					</Animator>
					<Animator
						animation={batch(Fade(), Sticky(), MoveOut(0, -50))}
					>
						<img
							style={{
								opacity: "0.3",
							}}
							src="/images/logos/img3.svg"
							alt="logo"
						/>
					</Animator>
				</ScrollPage> */}
			</SectionParent>
		</Fragment>
	);
}
