import { useAos } from "../../../hooks/useAos";
import { contentArray, paragraph } from "./data";
import { DescriptionLayout, RowitemLayout } from "./styles";

const Rowitem = ({ svg, number, heading, heading1, description }) => {
	useAos(1000);

	return (
		<RowitemLayout data-aos="fade-up">
			{svg}

			<p className="label">{number}</p>
			<p className="heading m-0">{heading}</p>
			<p className="heading">{heading1}</p>
			<p className="description">{description}</p>
		</RowitemLayout>
	);
};

const Description = () => {
	return (
		<DescriptionLayout id="features" data-aos-anchor-placement="top-bottom">
			<div
				className="section-label"
				data-aos="fade-up"
				style={{ marginTop: "8rem" }}
			>
				EXPLORE FEATURES
			</div>
			<p data-aos="fade-up" className="paragraph">
				{paragraph}
			</p>

			<div className="cardRow">
				{contentArray.map((item, index) => (
					<Rowitem
						key={index}
						svg={item.svg}
						number={item.number}
						heading={item.heading}
						heading1={item.heading1}
						description={item.description}
					/>
				))}
			</div>
		</DescriptionLayout>
	);
};

export default Description;
