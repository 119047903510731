import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const MainBox = styled.div`
	margin: 5rem 0;
	width: 100vw;
	display: flex;
	flex-wrap: wrap;

	@media (max-width: ${constants.breakpoints.mobile}) {
		flex-direction: column;
	}
`;

export const Card = styled.div`
	position: relative;
	width: 25%;
	height: 46vh;
	p {
		position: absolute;
		bottom: 1rem;
		left: 2rem;
		font-size: 2rem !important;
		font-weight: 500;
		height: fit-content;
	}

	&:hover {
		p {
			position: absolute;
			top: 1rem;
			font-size: 2rem !important;
			font-weight: 500;
		}
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		height: 50vh;
		width: 100%;

		p {
			position: absolute;
			top: 1rem;
			font-size: 2rem !important;
			font-weight: 500;
		}
	}
`;

export const Box = styled.div`
	padding: 2rem;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
	opacity: 0.2 !important;
	transition: all 0.5s ease-in;

	ul {
		margin: 2rem 0;
		height: 100%;
	}

	li {
		margin: 0.5rem 0;
		display: none;
		font-size: 1rem;
	}

	&:hover {
		flex-direction: column;
		justify-content: flex-start;
		opacity: 1 !important;

		li {
			display: list-item;
		}
	}
	@media (max-width: ${constants.breakpoints.mobile}) {
		flex-direction: column;
		justify-content: flex-start;
		opacity: 1 !important;

		li {
			display: list-item;
		}
	}
`;
