import styled from "styled-components";
import { constants } from "../../constants/constants";

export const PolicyLayout = styled.section`
	.heading {
		font-size: 5rem;
		font-weight: 800;
		margin-top: 10rem;
		background: url("/images/background.jpg");
		background-size: cover;
		background-position: center;
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		margin-bottom: 2.5rem;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		.heading {
			font-size: 2.7rem;
			margin-bottom: 1.5rem;
		}
	}

	font-size: 14px;
`;

export const DocumentViewLayout = styled.div`
	.introduction-paragraph {
		margin-bottom: 1rem;
	}

	.main-list {
		padding-left: 3rem;
		margin-bottom: 10rem;

		.point-heading {
			margin: 1rem 0 0 0.125rem;
			font-size: 1.5rem;
			font-weight: 700;
		}

		.point-paragraph {
			margin: 0.5rem 0;
		}

		.headersLi {
			&::marker {
				font-size: 1.4rem;
				font-weight: bold;
			}
		}

		ol {
			padding-left: 2rem;

			.subpoint-heading {
				font-size: 1.1rem;
				font-weight: 600;
				margin-bottom: 12px;
			}

			.subpoint-paragraph,
			.point-paragraph {
				margin-bottom: 12px;
			}
		}
	}
`;
