import { Fragment, useEffect } from "react";
import {
	BrowserRouter,
	Switch,
	Route,
	useLocation,
	Redirect,
} from "react-router-dom";

import { Home } from "../pages/home";
import Ico from "../pages/icoComponent/Ico";
import { Policies } from "../pages/policies/policies";
import { TermsConditions } from "../pages/terms/terms";
import Career from "../pages/career/Career";
import Jobs from "../pages/jobs/Jobs";
import PrivateSale from "../pages/privateSale/PrivateSale";
import JoinWaitList from "../pages/JoinWaitList/JoinWaitList";

import MobileOverlay from "../components/home/footer/MobileOverlay";

export const Routes = () => {
	return (
		<Fragment>
			<BrowserRouter>
				<ScrollToTop />
				<Switch>
					<Redirect from={`//:name`} to={`/:name`} />
					<Route path="/" exact component={Home} />

					<Route path="/policies" exact component={Policies} />
					<Route path="/terms" exact component={TermsConditions} />
					<Route path="/ico" exact component={Ico} />
					<Route path="/whitelist" exact component={JoinWaitList} />
					<Route exact path="/private-sale" component={PrivateSale} />
					<Route exact path="/career" component={Career} />
					<Route exact path="/career/:name" component={Jobs} />
				</Switch>
				<MobileOverlay />
			</BrowserRouter>
		</Fragment>
	);
};

const ScrollToTop = ({ children }) => {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return children || null;
};
