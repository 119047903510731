import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { constants } from "../../../constants/constants";

import { MobileOverlayLayout } from "./styles";

export default function MobileOverlay() {
	const isIpad = useMediaQuery({
		query: `(max-width: ${constants.breakpoints.ipad})`,
	});

	return (
		<>
			{isIpad && (
				<MobileOverlayLayout>
					<div className="overlay">
						<div>
							<a href="/lightpaper.pdf">
								<button className="btn PSBtn">
									Litepaper
								</button>
							</a>
						</div>
						<span className="text-light">|</span>
						<div>
							<a href="/ThoughtzPitchDeck.pdf">
								<button className="btn thzTokenbtn">
									Pitch Deck
								</button>
							</a>
						</div>
					</div>
				</MobileOverlayLayout>
			)}
		</>
	);
}
