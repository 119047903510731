import styled from "styled-components";

export const CareerLayout = styled.div`
	/* carrer page */
	width: 100%;

	.text-p {
		p {
			width: 100% !important;
			margin: 10px 0 !important;
		}
	}

	.arrow {
		transition: opacity 0.6s, transform 0.6s, -webkit-transform 0.6s,
			-moz-transform 0.6s;
		width: 55px;
		height: 55px;
		border: 1px solid rgba(250, 250, 250, 0.5);
		border-radius: 40px;
		cursor: pointer;
		transition: all linear 0.4s;
		transform: rotateZ(90deg);
		margin-top: 3.5rem;
		display: block;
		position: relative;
		bottom: -10rem;

		&:before {
			width: 45px;
			content: "";
			height: 2px;
			background: white;
			position: absolute;
			margin-top: -1px;
			right: 50%;
			top: 50%;
			transition: all 0.3s ease;
		}

		&:after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 5px;
			border-color: transparent transparent transparent white;
			position: absolute;
			margin-top: -5px;
			transition: all 0.3s ease;
			top: 50%;
			right: 45%;
		}

		&:hover {
			&:before {
				width: 20px;
				right: 20px;
			}

			&:after {
				right: 20px;
			}
		}
	}

	.carrer-container {
		width: 100%;
		min-height: 100vh;
		transition: background 1s ease;
	}
	.carrer-container .page1 {
		font-size: 3.5rem;
		font-weight: bold;
		transform: translateY(-25%);
		margin-top: 4.5rem;
	}

	.heading-grad {
		background: -webkit-gradient(
			linear,
			left top,
			right top,
			from(#ce55b0),
			color-stop(12%, #f34dac),
			color-stop(27%, #f48e66),
			color-stop(38%, #f0b832),
			color-stop(54%, #b2ce37),
			color-stop(70%, #63d7af),
			color-stop(84%, #1cc2df),
			color-stop(94%, #0580e6),
			to(#ce55b0)
		);
		background: -webkit-linear-gradient(
			left,
			#ce55b0,
			#f34dac 12%,
			#f48e66 27%,
			#f0b832 38%,
			#b2ce37 54%,
			#63d7af 70%,
			#1cc2df 84%,
			#0580e6 94%,
			#ce55b0
		);
		background: -moz-linear-gradient(
			left,
			#ce55b0 0,
			#f34dac 12%,
			#f48e66 27%,
			#f0b832 38%,
			#b2ce37 54%,
			#63d7af 70%,
			#1cc2df 84%,
			#0580e6 94%,
			#ce55b0 100%
		);
		background: linear-gradient(
			90deg,
			#ce55b0,
			#f34dac 12%,
			#f48e66 27%,
			#f0b832 38%,
			#b2ce37 54%,
			#63d7af 70%,
			#1cc2df 84%,
			#0580e6 94%,
			#ce55b0
		);
		background-size: 1000px;
		background-position: center;
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.separation {
		width: 150px;
		height: 3.5px;
		background: url("/images/background.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	.carrer-container .page2 {
		width: 400px;
		font-size: 1.5rem;
		font-weight: 500;
		margin: 2rem 0;
		/* letter-spacing: 2px; */
	}
	@media screen and (max-width: 500px) {
		.carrer-container {
			min-height: 110vh;
			.page1 {
				font-size: 2.5rem;
				left: 0;
				padding: 0 20px;
			}
			.page2 {
				font-size: 1rem;
				left: 0;
				padding: 0 20px;
			}
		}
		.carrer-container_content {
			min-height: 130vh;
		}
	}
	.carrer-container_content {
		height: 80vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		h2 {
			font-size: 1.5rem;
			font-weight: 600;
		}
	}
	.carrer-container_boxes {
		width: 90%;
		margin: 0 auto;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 40px;
		margin-top: 80px;
		justify-content: center;
		align-items: center;
	}
	@media screen and (max-width: 769px) {
		.carrer-container_boxes {
			grid-template-columns: 1fr;
		}
	}
	.carrer-container_boxes div {
		padding: 15px 20px;
		min-height: 150px;
		position: relative;
		background: linear-gradient(135deg, #d445ba, #e7a650);
	}
	.carrer-container_boxes span {
		position: absolute;
		bottom: 10px;
	}
	.carrer-container_boxes a {
		position: absolute;
		bottom: 0;
		right: 0;
		background: var(--main-color);
		color: #000;
		padding: 15px 20px;
	}
`;
