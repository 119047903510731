import { Flex } from "@chakra-ui/react";
import { constants } from "../../../constants/constants";
import styled from "styled-components";

export const ThoughtsLayout = styled.section`
	padding: 0px !important;

	.thoughtsWrapper {
		display: flex;
		margin-top: 5rem;
		justify-content: space-between;

		.heading {
			&:first-child {
				margin-top: 5rem;
			}

			background: linear-gradient(
				to right,
				rgb(208, 40, 155),
				rgb(249, 104, 119)
			);
			background-size: cover;
			background-position: center;
			-webkit-text-fill-color: transparent;
			-webkit-background-clip: text;
			background-clip: text;
			font-size: 60px;
			font-weight: 600;
			line-height: 70px;
		}

		.paragraph {
			margin-top: 3rem;
			font-size: 1.2rem;
			line-height: 1.9rem;
		}

		.animated-container-wrapper {
			width: 100%;
			height: 100%;
			max-height: 85vh;
			overflow: hidden;
		}
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		.thoughtsWrapper {
			flex-direction: column;
			margin-top: 1rem;

			.heading {
				font-size: 35px;
				line-height: 45px;
				font-weight: 700;
			}

			.paragraph {
				font-size: 14px;
				margin-bottom: 5rem;
				line-height: 1.5rem;
			}

			.animated-container-wrapper {
				margin-bottom: 5rem;
			}
		}
	}
`;

export const AnimatedContainer = styled(Flex)`
	animation: marqueeAnimation 35s linear infinite;

	@keyframes marqueeAnimation {
		from {
			transform: translateY(0);
		}

		to {
			transform: translateY(-50%);
		}
	}

	.cardColumn {
		@media (min-width: ${constants.breakpoints.mobile}) {
			&:first-child {
				transform: translateY(2.5rem);
			}

			&:nth-child(2) {
				transform: translateY(-3.5rem);
			}

			&:last-child {
				transform: translateY(4.5rem);
			}
		}

		@media (max-width: ${constants.breakpoints.mobile}) {
			transform: translateX(-12rem);
		}
	}
`;

export const CardLayout = styled.div`
	width: fit-content;
	width: 14rem;
	height: 11rem;
	border-radius: 8px;
	border: 2px solid ${({ color }) => color};
	padding: 1rem 1rem 2.6rem 1rem;
	font-size: 14px;
	margin-bottom: 1.5rem;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;

	.pattern {
		position: absolute;
		bottom: 0;
		left: 0;
	}

	.user-details {
		display: flex;
		margin-bottom: 10px;
		align-items: center;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		padding: 1.5rem 10px 3rem 10px;
	}
`;
