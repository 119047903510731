import { ReactComponent as Pattern1 } from "../../../svg/pattern-1.svg";
import { ReactComponent as Pattern2 } from "../../../svg/pattern-2.svg";
import { ReactComponent as Pattern3 } from "../../../svg/pattern-3.svg";
import { ReactComponent as Pattern4 } from "../../../svg/pattern-4.svg";

export const cardData = [
	{
		label: "‘Tinder for Thoughts’ really does the job for someone like me.",
		color: "rgb(255,239,171)",
		svg: (
			<Pattern1
				style={{
					width: 60,
					bottom: "-30px",
				}}
			/>
		),
		username: "Dan Lewis",
		avatarURL: "/images/Male/10.svg",
	},
	{
		label: "Seriously what is crypto?",
		color: "rgb(199,243,195)",
		svg: (
			<Pattern2
				style={{
					width: 60,
					bottom: "-20px",
				}}
			/>
		),
		username: "Noah Boah",
		avatarURL: "/images/Male/11.svg",
	},
	{
		label: "Traveling from home to work - I can finally write wanderlust in my bio.",
		color: "rgb(255,193,193)",
		svg: (
			<Pattern3
				style={{
					height: "90%",
					left: "unset",
					right: 10,
					top: 10,
				}}
			/>
		),
		username: "Doctor X",
		avatarURL: "/images/Male/12.svg",
	},
	{
		label: "Booking a one-way ticket to Himalayas",
		color: "rgb(255,208,159)",
		svg: (
			<Pattern4
				style={{
					// height: 35,
					width: "70",
					left: "30",
					bottom: 10,
				}}
			/>
		),
		username: "Killmonger",
		avatarURL: "/images/Male/13.svg",
	},
	{
		label: "Flying from NY to Italy",
		color: "rgb(164,222,255)",
		svg: (
			<Pattern1
				style={{
					width: 60,
					bottom: "-30px",
				}}
			/>
		),
		username: "Lizard",
		avatarURL: "/images/Male/14.svg",
	},
	{
		label: "Want to gain 10 kgs in next 2 months",
		color: "rgb(255,239,171)",
		svg: (
			<Pattern2
				style={{
					width: 60,
					bottom: "-20px",
				}}
			/>
		),
		username: "Muthoni Gaga",
		avatarURL: "/images/Male/15.svg",
	},
	{
		label: "Feel like having a cheesecake.",
		color: "rgb(199,243,195)",
		svg: (
			<Pattern3
				style={{
					height: "90%",
					left: "unset",
					right: 10,
					top: 10,
				}}
			/>
		),
		username: "Adarsh Pandey",
		avatarURL: "/images/Male/16.svg",
	},
	{
		label: "Last-minute gift ideas anyone?",
		color: "rgb(255,193,193)",
		svg: (
			<Pattern4
				style={{
					// height: 35,
					width: "70",
					left: "30",
					bottom: 10,
				}}
			/>
		),
		username: "George Gotti",
		avatarURL: "/images/Male/17.svg",
	},
	{
		label: "Cookies > cakes",
		color: "rgb(255,208,159)",
		svg: (
			<Pattern1
				style={{
					width: 60,
					bottom: "-30px",
				}}
			/>
		),
		username: "Muhammed Songur",
		avatarURL: "/images/Male/18.svg",
	},
	{
		label: "Hey Jude by the Beatles still tops my list",
		color: "rgb(164,222,255)",
		svg: (
			<Pattern2
				style={{
					width: 60,
					bottom: "-20px",
				}}
			/>
		),
		username: "Azam Biden",
		avatarURL: "/images/Male/19.svg",
	},
	{
		label: "Street dogs deserve more love",
		color: "rgb(255,239,171)",
		svg: (
			<Pattern3
				style={{
					height: "90%",
					left: "unset",
					right: 10,
					top: 10,
				}}
			/>
		),
		username: "Ruth Bright",
		avatarURL: "/images/Male/1.jpg",
	},
	{
		label: "Going vegan",
		color: "rgb(199,243,195)",
		svg: (
			<Pattern4
				style={{
					// height: 35,
					width: "70",
					left: "30",
					bottom: 10,
				}}
			/>
		),
		username: "Lawrence Odom",
		avatarURL: "/images/Female/1.jpg",
	},
	{
		label: "Suggestions on German restaurants in NY?",
		color: "rgb(255,193,193)",
		svg: (
			<Pattern1
				style={{
					width: 60,
					bottom: "-30px",
				}}
			/>
		),
		username: "Patrick Cherry",
		avatarURL: "/images/Male/2.jpg",
	},
	{
		label: "Must buy cryptos now",
		color: "rgb(255,208,159)",
		svg: (
			<Pattern2
				style={{
					width: 60,
					bottom: "-20px",
				}}
			/>
		),
		username: "Mina Mahoney",
		avatarURL: "/images/Female/2.jpg",
	},
	{
		label: "Best side hustle idea?",
		color: "rgb(164,222,255)",
		svg: (
			<Pattern3
				style={{
					height: "90%",
					left: "unset",
					right: 10,
					top: 10,
				}}
			/>
		),
		username: "Kianna Lester",
		avatarURL: "/images/Female/3.jpg",
	},
	{
		label: "Mountains or beaches?",
		color: "rgb(255,239,171)",
		svg: (
			<Pattern4
				style={{
					// height: 35,
					width: "70",
					left: "30",
					bottom: 10,
				}}
			/>
		),
		username: "Devin Wilson",
		avatarURL: "/images/Male/3.jpg",
	},
	{
		label: "Planning to visit Tulip gardens in Holland",
		color: "rgb(199,243,195)",
		svg: (
			<Pattern1
				style={{
					width: 60,
					bottom: "-30px",
				}}
			/>
		),
		username: "Madeleine Wiggins",
		avatarURL: "/images/Female/4.jpg",
	},
	{
		label: "Reading Bhagavad Gita",
		color: "rgb(255,193,193)",
		svg: (
			<Pattern2
				style={{
					width: 60,
					bottom: "-20px",
				}}
			/>
		),
		username: "Raven Compton",
		avatarURL: "/images/Male/4.jpg",
	},
	{
		label: "The more you know, the more you know you don’t know",
		color: "rgb(255,208,159)",
		svg: (
			<Pattern3
				style={{
					height: "90%",
					left: "unset",
					right: 10,
					top: 10,
				}}
			/>
		),
		username: "Valery Bryan",
		avatarURL: "/images/Female/5.jpg",
	},
	{
		label: "Failure isn’t the end, only the beginning",
		color: "rgb(164,222,255)",
		svg: (
			<Pattern4
				style={{
					// height: 35,
					width: "70",
					left: "30",
					bottom: 10,
				}}
			/>
		),
		username: "Darryl Ryan",
		avatarURL: "/images/Male/5.jpg",
	},
	{
		label: "Want to learn crypto",
		color: "rgb(255,239,171)",
		svg: (
			<Pattern1
				style={{
					width: 60,
					bottom: "-30px",
				}}
			/>
		),
		username: "Iyana Adkins",
		avatarURL: "/images/Female/6.jpg",
	},
	{
		label: "New year resolutions?",
		color: "rgb(199,243,195)",
		svg: (
			<Pattern2
				style={{
					width: 60,
					bottom: "-20px",
				}}
			/>
		),
		username: "Kody Villarreal",
		avatarURL: "/images/Male/6.jpg",
	},
	{
		label: " Looking for a new job.",
		color: "rgb(255,193,193)",
		svg: (
			<Pattern3
				style={{
					height: "90%",
					left: "unset",
					right: 10,
					top: 10,
				}}
			/>
		),
		username: "Gabriella Santana",
		avatarURL: "/images/Female/7.jpg",
	},
	{
		label: "Having trouble falling asleep… again",
		color: "rgb(255,208,159)",
		svg: (
			<Pattern4
				style={{
					// height: 35,
					width: "70",
					left: "30",
					bottom: 10,
				}}
			/>
		),
		username: "Noel Pratt",
		avatarURL: "/images/Male/7.jpg",
	},
	{
		label: " Any advice on dry skin?",
		color: "rgb(164,222,255)",
		svg: (
			<Pattern1
				style={{
					width: 60,
					bottom: "-30px",
				}}
			/>
		),
		username: "Ramiro Coffey",
		avatarURL: "/images/Male/8.jpg",
	},
	{
		label: "Should I take up yoga or will I end up hurting myself?",
		color: "rgb(255,239,171)",
		svg: (
			<Pattern2
				style={{
					width: 60,
					bottom: "-20px",
				}}
			/>
		),
		username: "Eugene Hayes",
		avatarURL: "/images/Male/9.jpg",
	},
];

export const paragraph =
	"Thoughtz is a simple method to interact in real-time with individuals all around the world for collaboration, information sharing, decision-making, or just plain fun. ";
