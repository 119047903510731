export const jobs = {
	12200: {
		head: "Mobile Software Engineer - Flutter",
		summary: {
			head: "Summary",
			content:
				"We are looking for a Flutter Developer to join our team! As a software engineer, you will work on our platform and wallet solutions. This is a rare opportunity for a strong technologist to join a well-positioned team at an early stage to deliver immediate, measurable and long-lasting impact. You will gain exposure to many aspects of developing and deploying crypto solutions in our app.",
		},
		youWill: {
			head: "You Will",
			content: [
				"You will be working on developing the app and scaling it to new and existing users worldwide.",
				"You will mainly work with Flutter to architect, build and scale the application",
				"You will also work, with the server-side APIs for the app",
				"You will be working in a fast-paced agile environment where you will have a lot of autonomy and ability to shape the future direction of the app",
				"You will work in a friendly, informal environment that supports your individual growth",
			],
		},
		youHave: {
			head: "You Have",
			content: [
				"Experience in web/mobile application development.",
				"You have experience in Flutter + Dart or a keen interest in Flutter + Dart and excellent skills in Typescript / Javascript.",
				"You have experience in server-side web application programming.",
				"You are able to write clean, modular, maintainable code that will perform on many platforms and devices.",
				"Experience in API design, performance optimization.",
				"You have a good, critical eye for details.",
				"You’re passionate about the quality of the software you design and deliver.",
				"You have experience implementing web applications in Java Understanding cryptocurrency space.",
				"Understanding of wallet and development of mobile wallet functionalities.",
			],
		},
		benefits: {
			head: "Our Benefits",
			content:
				"Extensive learning opportunities. Fully remote and flexible working hours.  Flexible public holidays, swap days off according to your values and beliefs.",
		},
		aboutUs: {
			head: "About Us",
			content:
				"At Thoughtz, we are on a mission to help people connect better and make things happen at the speed of the thought. We love collaboration. We value teams, and enjoy open communication and transparency. We passionately innovate to create experiences that delight users. We have amazing plans to grow the app and a lot of exciting work ahead of us and looking forward to welcome passionate people to join our team.",
		},
	},
	12201: {
		head: "Senior Software Engineer - Django",
		summary: {
			head: "Summary",
			content:
				"We’re currently looking for new Python Engineer to join our team to deliver complex web applications written in Python, primarily using AWS and Django. You’ll be developing high quality application in the crypto space. As a Specialist Engineer you will spend the majority of your time coding and will be heavily involved in making key architectural decisions throughout your project’s lifecycle. We aim to strike a balance between leveraging our existing strengths and exploring new tech. While this listing focusses on Python, we try to avoid silos: our developers are encouraged to contribute to any part of a system they’d like to.",
		},
		youWill: {
			head: "You Will",
			content: [
				"Delivering a very high standard of technical work and supporting your team in doing the same (e.g. encouraging best practices and ensuring a high quality codebase).",
				"Keeping up to date with the industry, community, and ecosystem surrounding your specialism.",
				"Advocating for your specialism and providing strong guidance on technical decisions around the app and assisting the team’s decision making in this area.",
				"Developing and maintaining a shared vision and consistency for your specialism across the company.",
				"You must have an appreciation of the full web stack, as well as an appreciation of current trends in both backend and frontend web technologies, and have an understanding of best practices including; testing, security, accessibility and code quality.",
			],
		},
		youHave: {
			head: "You Have",
			content: [
				"Proven experience building for the web/mobile using Python.",
				"Detailed knowledge and long-term experience with a backend Python framework such as Flask, Django or FastAPI.",
				"Proven experience of model design on relational and non-relational databases.",
				"Experience with AWS /Google App Engine or a similar PaaS.",
				"An understanding of security vulnerabilities and how to prevent them.",
				"A high-level understanding of frontend development.",
				"Experience launching high-quality digital products.",
				"Proven experience creating secure and well-designed APIs.",
				"Proven experience building for scale: high traffic, big data, large feature sets.",
				"Proven front-end web or native app development skills.",
				"Knowledge of testing, QA tools and methodologies.",
				"Familiarity with Agile methodologies such as Scrum and Kanban.",
			],
		},
		benefits: {
			head: "Our Benefits",
			content:
				"Extensive learning opportunities. Fully remote and flexible working hours. Flexible public holidays, swap days off according to your values and beliefs.",
		},
		aboutUs: {
			head: "About Us",
			content:
				"At Thoughtz, we are on a mission to help people connect better and make things happen at the speed of the thought. We love collaboration. We value teams, and enjoy open communication and transparency. We passionately innovate to create experiences that delight users. We have amazing plans to grow the app and a lot of exciting work ahead of us and looking forward to welcome passionate people to join our team.",
		},
	},
	12202: {
		head: "Brand Marketing Manager",
		summary: {
			head: "Summary",
			content:
				"We are looking for an innovative and experienced marketing mind to develop and execute branding, marketing strategies and creative campaigns.",
		},
		youWill: {
			head: "You Will",
			content: [
				"Plan, manage and executive companys branding strategy from end to end.",
				"Formulate digital marketing, PR & Communication, and social media strategy.",
				"Oversee and execute multi-channel campaigns to develop brand awareness.",
				"Develop and execute influencer marketing strategies and campaign planning.",
				"Draft and negotiate influencer agreements.",
				"Manage branding budget and maximise the ROI of all brand activities.",
				"Establish and measure brand KPIs to achieve brand growth.",
				"Responsible for external communications such as press release are consistent with brand strategy, including crisis management.",
				"Keep up to date with competitors analysis and market trends to maintain branding activities are innovative.",
				"Partner with cross departments to ensure companys goals are met.d	",
			],
		},
		youHave: {
			head: "You Have",
			content: [
				"3 to 5 years of brand marketing/brand/influencer marketing and communication experience.",
				"Experience with Blockchain/Crypto/Defi/Fintech/NFT related sector is a must.",
				"Proven experience of successful campaign planning and delivery.",
				"Successful track-record in delivering brand growth and brand activation across multiple communication channels.",
				"Strong writing and verbal communication skill, including copywriting and editing.",
				"Data-oriented, deep understanding of digital marketing, social media marketing.",
				"Knowledge of the concept of Growth Hack is preferable.",
			],
		},
		benefits: {
			head: "Our Benefits",
			content:
				"Extensive learning opportunities. Fully remote and flexible working hours. Flexible public holidays, swap days off according to your values and beliefs. Be the owner of your own learning curve. The possibilities are limitless and depend on you.",
		},
		aboutUs: {
			head: "About Us",
			content:
				"At Thoughtz, we are on a mission to help people connect better and make things happen at the speed of the thought. We love collaboration. We value teams, and enjoy open communication and transparency. We passionately innovate to create experiences that delight users. We have amazing plans to grow the app and a lot of exciting work ahead of us and looking forward to welcome passionate people to join our team.",
		},
	},
};

export const inputFields = [
	{
		type: "text",
		name: "first_name",
		required: true,
		label: "First Name",
	},
	{
		type: "text",
		name: "last_name",
		required: true,
		label: "Last Name",
	},
	{
		type: "email",
		name: "email",
		required: true,
		label: "Email",
	},
	{
		type: "tel",
		name: "phone",
		required: false,
		label: "Phone",
	},
	// {
	// 	type: "text",
	// 	name: "resume",
	// 	required: true,
	// 	label: "Resume Link",
	// },
	{
		type: "url",
		name: "linkedin",
		required: true,
		label: "Linkedin",
	},
	// {
	// 	type: "text",
	// 	name: "des",
	// 	required: true,
	// 	label: "How did you hear about this job?",
	// },
];
