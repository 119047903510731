import { Fragment } from "react";
import { Text } from "@chakra-ui/react";
import {
	teamMembers,
	teamMembers2,
	teamMembers3,
	// teamMembers4,
	// teamMembers5,
	advisors,
} from "./data";
// import { useMediaQuery } from "react-responsive";
// import { constants } from "../../../constants/constants";
import {
	// QuotesLayout,
	RowLayout,
	TeamLayout,
	TeamPersonLayout,
	TeamPersonLayout1,
	AdvisorLayout,
} from "./styles";
import { useAos } from "../../../hooks/useAos";
import linkedIn from "../../../images/linkedin.png";

// const QuotesDetails = ({ description, quote, skills }) => {
// 	return (
// 		<QuotesLayout className="team-person-quoted">
// 			<p className="quote-heading">{quote}</p>

// 			<div className="quote-description">
// 				<p>{description}</p>
// 			</div>

// 			<div className="skills">
// 				{skills.map((item) => (
// 					<p>{"> " + item}</p>
// 				))}
// 			</div>
// 		</QuotesLayout>
// 	);
// };

const TeamPerson = ({
	url,
	index,
	name,
	position,
	// description,
	// skills,
	// quote,
	linkedinURL,
}) => {
	// const isMobile = useMediaQuery({
	// 	query: `(max-width: ${constants.breakpoints.mobile})`,
	// });

	useAos(1000);

	return (
		<Fragment>
			<TeamPersonLayout url={url} index={index}>
				{/* {!isMobile && (
					<QuotesDetails
						description={description}
						quote={quote}
						skills={skills}
					/>
				)} */}
				<div data-aos="fade-up" className="team-person-details">
					<div>
						<p
							style={{
								fontSize: "2rem",
								fontWeight: "600",
								lineHeight: "2.5rem",
								width: "20rem",
								maxWidth: "100rem",
							}}
						>
							{name}
						</p>
						<Text color="rgb(226,39,144)">{position}</Text>
					</div>
					<div className="linkedInIco">
						<a href={linkedinURL} target="_blank" rel="noreferrer">
							{linkedinURL && (
								<img
									width="30px"
									src={linkedIn}
									alt="linkedIn-logo"
								/>
							)}
						</a>
					</div>
				</div>
			</TeamPersonLayout>

			{/* {isMobile && (
				<QuotesDetails
					description={description}
					quote={quote}
					skills={skills}
				/>
			)} */}
		</Fragment>
	);
};
const TeamPerson1 = ({
	url,
	index,
	name,
	position,
	// description,
	// skills,
	// quote,
	linkedinURL,
}) => {
	// const isMobile = useMediaQuery({
	// 	query: `(max-width: ${constants.breakpoints.mobile})`,
	// });

	useAos(1000);

	return (
		<Fragment>
			<TeamPersonLayout1 url={url} index={index}>
				{/* {!isMobile && (
					<QuotesDetails
						description={description}
						quote={quote}
						skills={skills}
					/>
				)} */}
				<div data-aos="fade-up" className="team-person-details">
					<div>
						<p
							style={{
								fontSize: "2rem",
								fontWeight: "600",
								lineHeight: "2.5rem",
								width: "20rem",
								maxWidth: "100rem",
							}}
						>
							{name}
						</p>
						<Text color="rgb(226,39,144)">{position}</Text>
					</div>
					<div className="linkedInIco">
						<a href={linkedinURL} target="_blank" rel="noreferrer">
							{linkedinURL && (
								<img
									width="30px"
									src={linkedIn}
									alt="linkedIn-logo"
								/>
							)}
						</a>
					</div>
				</div>
			</TeamPersonLayout1>

			{/* {isMobile && (
				<QuotesDetails
					description={description}
					quote={quote}
					skills={skills}
				/>
			)} */}
		</Fragment>
	);
};

const Row = ({ members }) => {
	return (
		<RowLayout data-aos="fade-up">
			{members.map((item, index) => (
				<TeamPerson key={index} index={index} {...item} />
			))}
		</RowLayout>
	);
};

const Row1 = ({ members }) => {
	return (
		<RowLayout data-aos="fade-up">
			{members.map((item, index) => (
				<TeamPerson1 key={index} index={index} {...item} />
			))}
		</RowLayout>
	);
};

const Advisor = ({ advisor, key }) => {
	return (
		<AdvisorLayout url={advisor.url} key={key}>
			<div data-aos="fade-up" className="team-person-details">
				<div>
					<p
						style={{
							fontSize: "2rem",
							fontWeight: "600",
							lineHeight: "2.5rem",
							width: "20rem",
							maxWidth: "100rem",
						}}
					>
						{advisor.name}
					</p>
					<Text color="rgb(226,39,144)">{advisor.position}</Text>
				</div>
				<div className="linkedInIco">
					<a
						href={advisor.linkedinURL}
						target="_blank"
						rel="noreferrer"
					>
						{advisor.linkedinURL && (
							<img
								width="30px"
								src={linkedIn}
								alt="linkedIn-logo"
							/>
						)}
					</a>
				</div>
			</div>
		</AdvisorLayout>
	);
};

const Team = () => {
	useAos(1000);
	return (
		<TeamLayout id="team">
			<div data-aos="fade-up" className="heading">
				Our Team
			</div>

			{[teamMembers, teamMembers2, teamMembers3].map((item, index) => (
				<Row members={item} key={index} />
			))}
			{/* {[teamMembers5].map((item, index) => (
				<Row1 members={item} key={index} />
			))} */}

			<div
				data-aos="fade-up"
				className="heading"
				style={{ marginTop: "8rem" }}
			>
				Our Advisors
			</div>

			<RowLayout data-aos="fade-up">
				{advisors.map((advisor, index) => (
					<Advisor advisor={advisor} key={index} />
				))}
			</RowLayout>
		</TeamLayout>
	);
};

export default Team;
