export const career = {
	head1: "Let's build the product together.",
	section:
		"We challenge you, you challenge us. We learn, grow and succeed as one. Be a part of what comes next.",
	head2: "Up For the challenge?",
	head3: "See available positions.",
	boxes: [
		{
			position: "Mobile Software Engineer - Flutter",
			type: "Full-time",
			link: "/career/mobile-software-engineer-flutter?job_id=12200",
		},
		{
			position: "Senior Software Engineer - Django",
			type: "Full-time",
			link: "/career/senior-software-engineer-django?job_id=12201",
		},
		{
			position: "Brand Marketing Manager",
			type: "Full-time",
			link: "/career/brand-marketing-manager?job_id=12202",
		},
	],
};
