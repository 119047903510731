import styled from "styled-components";

export const HeaderStyles = styled.div`
	.parents {
		background-color: rgb(15 18 22);
		margin: 30px 0;
		padding: 1.5rem 1.5rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.headerStyle {
		font-size: 2rem;
	}

	.divider {
		/* padding: 5px; */
		height: 4px;
		background-color: rgba(242, 43, 179, 1);
		border-radius: 10px;
		width: 60px;
		margin: 1rem;
		opacity: 1;
	}

	.timeBox {
		background-color: #000;
		padding: 1rem;
		margin: 5px;
		border-radius: 8px;
		text-align: center;
		width: 150px;
		h1 {
			font-size: 3rem;
		}

		p {
			color: rgba(242, 43, 179, 1);
			font-size: 1rem;
			font-weight: 600;
		}
	}

	.progress-div {
		margin: 1rem 0;
		width: 90%;
		display: flex;
		justify-content: space-between;
		border-radius: 0.25rem;
		font-size: 0.85rem;
		.grad-div {
			padding: 0.125rem 0.5rem;
			background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
			width: 65%;
		}
		.non-grad-div {
			padding: 0.125rem 0.5rem;
			width: 35%;
			display: flex;
			justify-content: flex-end;
			color: #333;
			background-color: #fff;
		}
	}

	.primaryColor {
		color: rgba(242, 43, 179, 1);
	}

	.progressBar {
		background-color: rgba(242, 43, 179, 1);
	}

	.ctyText {
		margin: 10px 0;
		font-size: 1.1rem;
		word-spacing: 5px;
	}

	.buyCoinBtn {
		background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
		font-size: 0.9rem;
		&:hover {
			background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
		}
	}
`;
