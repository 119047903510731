import { ReactComponent as SVG1 } from "../../../svg/svg-1.svg";
import { ReactComponent as SVG2 } from "../../../svg/svg-2.svg";
import { ReactComponent as SVG3 } from "../../../svg/svg-3.svg";
import { ReactComponent as SVG4 } from "../../../svg/svg-4.svg";

// export const paragraph= "Our specialized teams lead the product design and development process for high growth companies."
export const paragraph =
	"Your go-to space to share your ideas, thoughts, questions - and earn rewards!";

export const contentArray = [
	{
		svg: <SVG1 width="82px" height="97px" />,
		number: "01",
		heading: "Thoughtmatches",
		heading1: "(powered by AI)",
		description:
			"Thoughtz leverages artificial intelligence to match you with people who share your thoughts. Maybe you're a thinker and searching for other like-minded individuals, or you have a great idea and want to connect with like-minded thinkers.",
	},
	{
		svg: <SVG2 width="82px" height="97px" />,
		number: "02",
		heading: "Real Time Interactions",
		heading1: "",
		description:
			"Thoughtz connects the sharers' and gatherers' in real-time via matching thought interactions, keeping all talks up to date without delay. People will connect in real-time just by matching their ideas and making things happen at the speed of thought.",
	},
	{
		svg: <SVG3 width="90px" height="97px" />,
		number: "03",
		heading: "Intact Privacy",
		heading1: "",
		description:
			"The information exchanged between users will be automatically erased from the application and servers. There are separate options to save the chat for future purposes if any party wants to. Not only chats but also the connection will be terminated when the 24-hour timer is over.",
	},
	{
		svg: <SVG4 width="80px" height="97px" />,
		number: "04",
		heading: "Earn Rewards",
		heading1: "",
		description:
			"Anyone can earn rewards by sharing their knowledge. Get rewarded in Thoughtz coins as tips for sharing your expertise. It's a way to thank someone for making an exceptional contribution. Share to earn!",
	},
];
