export const inputFields = [
	{
		type: "text",
		name: "first_name",
		required: true,
		label: "First Name",
	},
	{
		type: "text",
		name: "last_name",
		required: true,
		label: "Last Name",
	},
	{
		type: "email",
		name: "email",
		required: true,
		label: "Email",
	},
	{
		type: "text",
		name: "country",
		required: true,
		label: "Country",
	},
	// {
	// 	type: "text",
	// 	name: "resume",
	// 	required: true,
	// 	label: "Resume Link",
	// },
	{
		type: "url",
		name: "message",
		required: false,
		label: "Message",
	},
	// {
	// 	type: "text",
	// 	name: "des",
	// 	required: true,
	// 	label: "How did you hear about this job?",
	// },
];
