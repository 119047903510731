import styled from "styled-components"
import { constants } from "../../../constants/constants"

export const DescriptionLayout= styled.section`

    .sectionLabel {
        letter-spacing: 5.5px;
        margin-bottom: 3.5rem;
        font-size: 14px;
        font-weight: 500;
    }

    .paragraph {
        margin-top: 1rem;
        width: 200%;
        font-weight: 600;
        font-size: 5rem;
        background: linear-gradient(90deg, rgb(208, 40, 155), 50%, #e7a650);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .cardRow {
        margin-top: 10rem;
        display: flex;
        justify-content: space-between;
    }

    @media (max-width: ${ constants.breakpoints.mobile }) {
        .section-label {
            letter-spacing: 3px;
            margin-bottom: 1rem;
        }

        .paragraph {
            font-weight: normal;
            font-size: 1.4rem;
        }

        .cardRow {
            flex-direction: column;
            margin-top: 7.5rem;
        }
    }
`

export const RowitemLayout= styled.div`

    padding: 0 5px;
    position: relative;
    transition: all 0.6s ease-in;

    svg {
        margin-bottom: 3.5rem;
    }

    circle, rect {
        transition: all 0.4s;
    }

    #svg-1 circle {
        transform-origin: 64.5px 18.5px;
    }

    #svg-3 circle {
        transform-origin: 45px 45px;
    }

    #svg-4 rect {
        transform-origin: 39.5px 50.5px;
    }

    &:hover {
        #svg-1 circle {
            &:last-child {
                transform: scale(1.714);
            }
        }

        #svg-2 circle {
            &:first-child {
                transform: translateY(15px);
            }

            &:last-child {
                transform: translateY(-15px);
            }
        }

        #svg-3 circle {
            &:first-child {
                transform: scale(2.1538);
            }

            &:last-child {
                transform: scale(0.6512);
            }
        }

        #svg-4 rect {

            &:first-child {
                transform: translate(-15px,16px);
            }

            &:last-child {
                transform: translate(16px,-15px);
            }
        }
    }

    .label {
        color: #9c9ea5;
        margin-bottom: 1.5rem;
    }

    .heading {
        font-weight: 600;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .description {
        margin-bottom: 7rem;
        color: grey;
    }

    @media (max-width: ${ constants.breakpoints.mobile }) {
        .label {
            margin-bottom: 0.5rem;
        }

        .description {
            margin-bottom: 3.5rem;
        }

        svg {
            margin: 0 auto;
        }
    }
`


export const Paragraph = styled.p`
    font-size: 4rem;
    margin-top: 1rem;
    font-weight: 600;
    background: linear-gradient(90deg, rgb(208, 40, 155), 50%, #e7a650);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: ${ constants.breakpoints.mobile }) {
        font-size: 3rem;
    }
`
