import { useEffect } from "react";
import { FAQ } from "../components/home/faq/faq";
import { MeetUp } from "../components/home/meet/meetUp";
import { ScrollContainer } from "react-scroll-motion";
import { Footer } from "../components/home/footer/footer";
import MobileOverlay from "../components/home/footer/MobileOverlay";
import { Navbar } from "../components/global/navbar/navbar";
import { Landing } from "../components/home/landing/landing";
import Concept from "../components/home/concept/concept";
import Description from "../components/home/description/description";
import About from "../components/home/about/about";
import Team from "../components/home/team/team";
import Header from "../components/home/header/header";
import Reviews from "../components/home/reviews/reviews";
import Thoughts from "../components/home/thoughts/thoughts";
import Donation from "../components/home/donation/donation";
import Overview from "../components/home/overview/overview";
import Platforms from "../components/home/platforms/platforms";
import Blog from "../components/home/blogs/blogs";
import Press from "../components/home/press/press";
import { constants } from "../constants/constants";
import { useMediaQuery } from "react-responsive";
import Roadmap from "../components/icoComponent/Roadmap/Roadmap";

export const Home = () => {
	useEffect(() => {
		document.title = "Home | Thoughtz.ai";
	}, []);

	const isMobile = useMediaQuery({
		query: `(max-width: ${constants.breakpoints.ipad})`,
	});

	const data = [
		{
			id: 0,
			header: "Q1, 2021",
			content: [
				"Initial Brainstorming",
				"Market and Competitor research",
				"User Feedback analysis",
				"Built the Foundation and Structure of the idea ",
			],
		},
		{
			id: 1,
			header: "Q2, 2021",
			content: [
				"Finalized the features of the product",
				"Initialized the Designs",
				"Finalized the Prototype",
			],
		},
		{
			id: 2,
			header: "Q3, 2021",
			content: [
				"Building a team",
				"App Development",
				"Developed MVP",
				"Obtained feedback from initial test users",
			],
		},
		{
			id: 3,
			header: "Q4, 2021",
			content: [
				"Development of Alpha version of the App",
				"Drafted the White paper",
				"Website and Social Media channels release",
			],
		},
		{
			id: 4,
			header: "Q1, 2022",
			content: [
				"Strategic Partnerships",
				"Marketing",
				"Anti Snipe Protection",
				"Smart Contract Development",
			],
		},
		{
			id: 5,
			header: "Q2, 2022",
			content: [
				"Seed, Private and Public sale",
				"Thoughtz App beta version",
				"Listing in DEX (Pancakeswap)",
				"Acquisition of Users",
				"Bridge contracts in Polygon Matic and Avalanche",
			],
		},
		{
			id: 6,
			header: "Q3, 2022",
			content: [
				"Onboard Experts and Influencers",
				"Mint Thought NFTs",
				"Implement Ads and Premium features",
				"Implement Groups",
				"Exchange listing",
				"Build Decentralized app",
				"Thoughts NFT marketplace",
			],
		},
		{
			id: 7,
			header: "Q4, 2022",
			content: [
				"Expert Marketplace",
				"Live Streaming",
				"Implement Poll features and get feedback on your thoughts",
				"Brands offering products for discounts",
				"Data Analytics and APIs",
			],
		},
	];

	return (
		<>
			<Navbar />
			<ScrollContainer>
				<MeetUp />
				<Landing />
			</ScrollContainer>
			<Concept />
			<Description />
			<Thoughts />
			<Overview />
			<div style={{ marginTop: "3rem", maxWidth: "1400px" }}>
				<Roadmap data={data} />
			</div>
			<About />
			<Team />
			<Reviews />
			{/* <Press/> */}
			<Platforms />
			{/* <Donation /> */}
			{!isMobile && (
				<div style={{ marginTop: "5rem" }}>
					<Header />
				</div>
			)}
			<Blog />
			<FAQ />
			<Footer />
			{/* <MobileOverlay /> */}
		</>
	);
};
