export const reviews = [
	{
		avatar: "/images/Female/7.jpg",
		review: "Thoughtz is a great app for sharing your thoughts and ideas all over the world! I am amazed how easily i can get connected and share my thoughts instantly. And it's really simple!",
		name: "Nandini M",
		user: "nandini",
	},
	{
		avatar: "/images/Female/1.jpg",
		review: "I am excited about thoughtz and I want to share my thoughts with everyone, thoughtz makes it really easy and quick.",
		name: "Sarah",
		user: "sarah",
	},
	{
		avatar: "/images/Male/3.jpg",
		review: "Thoughtz is an innovative new way to share ideas and thoughts. I can already see so many ways that it can be used in my personal life as well as in my professional life.",
		name: "Stephen",
		user: "stephen",
	},
];
