import { Container, Text, Flex, Avatar } from "@chakra-ui/react";
import { ReactComponent as QuoteTopSVG } from "../../../svg/quotes-top.svg";
import { ReactComponent as QuoteBottomSVG } from "../../../svg/quotes-bottom.svg";
import { Carousel } from "react-bootstrap";
import {
	QuoteSVGContainer,
	ReviewDetailsLayout,
	ReviewsLayout,
} from "./styles";
import { useAos } from "../../../hooks/useAos";
import { reviews } from "./data";

const Review = ({ review, name, user, avatar }) => {
	return (
		<ReviewDetailsLayout>
			<Container maxWidth="1000px" padding="0px" margin="0px">
				<p className="paragraph">{review}</p>

				<Flex alignItems="center">
					<Avatar
						src={avatar}
						width="75px"
						height="75px"
					/>
					<Container padding="0" margin="0 0 0 2rem">
						<Text fontSize="1.5rem" fontWeight="600">
							{name}
						</Text>
						<Text color="rgb(110,164,248)">@{user}</Text>
					</Container>
				</Flex>
			</Container>
		</ReviewDetailsLayout>
	);
};

const Reviews = () => {
	useAos(1000);

	return (
		<ReviewsLayout>
			<div data-aos="fade-up" className="carouselWrapper">
				<QuoteSVGContainer position="top">
					<QuoteTopSVG width="100%" height="100%" />
				</QuoteSVGContainer>
				<Carousel controls={false}>
					{/* {new Array(3).fill(0).map((_, index) => (
						<Carousel.Item key={index}>
							<Review />
						</Carousel.Item>
					))} */}
					{reviews.map((review, index) => (
						<Carousel.Item key={index}>
							<Review review={review.review} name={review.name} user={review.user} avatar={review.avatar} />
						</Carousel.Item>
					))}
				</Carousel>
				<QuoteSVGContainer position="bottom">
					<QuoteBottomSVG width="100%" height="100%" />
				</QuoteSVGContainer>
			</div>
		</ReviewsLayout>
	);
};

// const paragraph =
// 	"Clover has not only become the central place for all my notes and todos, it’s also become my go-to space when I need to think and work on complex problems. It’s crazy fast, and I love how it’s helping me organize my thoughts in a visual way.";

export default Reviews;
