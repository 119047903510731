export const navLinks = [
	{
		navLink: "Home",
		route: "/",
	},
	{
		navLink: "How it works",
		route: "/#concept",
	},
	{
		navLink: "Features",
		route: "/#features",
	},
	{
		navLink: "About us",
		route: "/#about",
	},
	{
		navLink: "Careers",
		route: "/career",
	},
	// {
	// 	navLink: "Private sale",
	// 	route: "/private-sale",
	// },
	{
		navLink: "Litepaper",
		route: "/lightpaper.pdf",
	},
];

export const topNav = [
	{
		imageURL: "/images/footer/social/telegram.svg",
		link: "https://t.me/thoughtzAi ",
		name: "Telegram",
	},
	{
		imageURL: "/images/footer/social/twitter.svg",
		link: "https://twitter.com/ThoughtzAi",
		name: "Twitter",
	},
	{
		imageURL: "/images/footer/social/instagram.svg",
		link: "https://www.instagram.com/thoughtz.ai/",
		name: "Instagram",
	},
	{
		imageURL: "/images/footer/social/medium.svg",
		link: "https://thoughtz.medium.com/",
		name: "Medium",
	},
];
