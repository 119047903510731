import { MeetLayout } from "./stylesUp";
import { motion } from "framer-motion";
import { animateScroll as scroll } from "react-scroll";
import {
	ScrollPage,
	Animator,
	batch,
	MoveOut,
	Fade,
	StickyOut,
} from "react-scroll-motion";


export const MeetUp = () => {
	const scrollNext = () => {
		scroll.scrollMore(1700, {
			duration: 100,
			delay: 0,
			smooth: "easeInOutQuart",
		});
	};

	return (
		<MeetLayout id="meet">
			<video className='videoTag mob' autoPlay loop muted playsInline >
				<source src="https://res.cloudinary.com/dvnedmwwz/video/upload/v1642697052/Thoughtz/landingVideo_msdom5.mp4" type='video/mp4' />
				<source src="https://res.cloudinary.com/dvnedmwwz/video/upload/v1642697052/Thoughtz/landingVideo_msdom5.mp4" type="video/ogg"/>
			</video>
			<ScrollPage page={0}>
				<Animator animation={batch(Fade(), StickyOut(), MoveOut())}>
					<div className="hero">
						<video className='videoTag des' autoPlay loop muted playsInline >
							<source src="https://res.cloudinary.com/dvnedmwwz/video/upload/v1642697052/Thoughtz/landingVideo_msdom5.mp4" type='video/mp4' />
							<source src="https://res.cloudinary.com/dvnedmwwz/video/upload/v1642697052/Thoughtz/landingVideo_msdom5.mp4" type="video/ogg"/>
						</video>
						<div className="top-section">
							<motion.h1
								className="heading-grad"
								initial={{ y: 60, opacity: 0 }}
								animate={{ y: 0, opacity: 1 }}
								transition={{ duration: 1 }}
							>
								<span className="think">Think. </span> <br/>
                <span className="connect">Connect. </span><br/>
                <span className="earn">Earn.</span><br/>
							</motion.h1>
							<motion.h2
								className=""
								initial={{ y: 60, opacity: 0 }}
								animate={{ y: 0, opacity: 0.7 }}
								transition={{ duration: 1.5 }}
							>
								Great minds think alike and <br/>   we love
								when these minds connect!
							</motion.h2>

						</div>
						<div className="scroll-down">
							<motion.div
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								transition={{ delay: 1.4, duration: 1 }}
							>
								<span
									className="arrow d-sm-block d-none scrollIndi"
									onClick={scrollNext}
								></span>
							</motion.div>
						</div>
					</div>
				</Animator>
			</ScrollPage>
		</MeetLayout>
	);
};
