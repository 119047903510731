import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const NavbarLayout = styled.nav`
	position: fixed;
	right: 0;
	left: 0;
	top: 0;
	z-index: 100;
	background: black;

	.topNav {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		border-bottom: 1px solid rgba(50, 50, 50, 0.4);
		padding: 5px 1.5rem 5px 1rem;
		box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
		opacity: 0.6;

		.topNavLink {
			margin: 5px 7px;
			font-size: 12px;
			color: rgb(220, 220, 220, 1);
			font-weight: 600;
		}
		.ico {
			background: #fff;
			width: 25px;
			height: 25px;
			border-radius: 5px;
			padding: 3px;
		}

		&:hover {
			opacity: 1;
			transition: 0.5s;
		}

		@media (max-width: ${constants.breakpoints.ipad}) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			opacity: 1;

			.topNavLink {
				margin: 5px 3px;
			}
		}
	}

	.nav-bar {
		padding: 0.5rem 1rem 0.5rem 1.5rem;

		.brand-logo {
			svg {
				margin-right: 1rem;
			}

			p {
				font-weight: 600;
				font-size: 1.5rem;
				/* font-family: 'Poppins'; */
			}

			.logo-item {
				margin-right: 1rem;
				width: 3rem;
			}
		}

		.nav-options {
			.nac-link,
			p,
			button,
			svg {
				margin: 0 1rem;
				cursor: pointer;
			}

			.nac-link,
			p,
			button {
				font-size: 1.1rem;
			}

			.live {
				background: red;
				padding: 1px 5px;
				border-radius: 10px;
				font-size: 10px;
				position: absolute;
				margin-top: -10px;
				margin-left: -10px;
			}

			button {
				background: linear-gradient(to right bottom, #d445ba, #e7a650);
				padding: 5px 10px;
				border-radius: 5px;
				font-weight: 600;

				&:hover {
					background: linear-gradient(
						to right bottom,
						#40c9ff,
						#e81cff
					);
				}
			}

			svg {
				display: none;
			}

			@media (max-width: ${constants.breakpoints.ipad}) {
				svg {
					display: block;
				}
			}
		}
	}

	.mobile-menu {
		width: 100%;
		height: calc(100vh - 68px);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		button {
			background: linear-gradient(to right bottom, #d445ba, #e7a650);
			width: 15rem;
			height: 3rem;
			border-radius: 5px;
			font-weight: 600;
			margin-bottom: 1.5rem;

			&:hover {
				background: linear-gradient(to right bottom, #40c9ff, #e81cff);
			}
		}

		p {
			font-size: 1.3rem;
			font-weight: 600;
			margin-bottom: 1rem;
		}
	}
`;
