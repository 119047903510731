import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const PrivateSaleTableLayout = styled.section`
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	.labelCard {
		margin: 10px 0;
		padding: 1.5rem 2.5rem;
		border-radius: 15px;
		background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);

		h5 {
			font-weight: 600;
			margin: 10px 0;
		}
		h1 {
			font-size: 3rem;
			font-weight: 600;
		}

		@media (max-width: ${constants.breakpoints.mobile}) {
			h5 {
				font-weight: 600;
				margin: 10px 0;
			}
			h1 {
				font-size: 2rem;
				font-weight: 600;
			}
		}
	}

	.tableLayout {
		background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
		border-radius: 15px;
		padding: 2rem 3rem;
		width: 98%;
		margin: 2rem 0;

		table {
			border-collapse: separate;
		}

		thead {
			background: #000;
		}

		th {
			padding: 15px 20px;
			min-width: 150px;
			border-radius: 8px;
			border: solid black 1px;
		}

		td {
			padding: 15px 20px;
		}

		tbody tr {
			border-bottom: 1px solid rgba(220, 220, 220, 0.4);
		}
	}

	.cardLayout {
		background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
		padding: 5px;
		margin: 10px 14px;
		border-radius: 8px;
		/* width: 85%; */

		.cardLayout0 {
			background: #000;
			padding: 1rem 1.5rem;
			border-radius: 8px;

			h1 {
				font-size: 2rem;
				font-weight: 600;
				margin: 10px 0;
				color: #f22bb2;
			}
			p {
				margin-bottom: 5px;
				span {
					color: rgb(180, 180, 180);
				}
			}
		}
	}
`;
