import { Avatar, Container, Flex, Text } from "@chakra-ui/react";
import { AnimatedContainer, CardLayout, ThoughtsLayout } from "./styles";
import { cardData, paragraph } from "./data";
import _shuffle from "lodash.shuffle";
import { useAos } from "../../../hooks/useAos";

const Card = ({ color, label, svg, avatarURL, username }) => {
	return (
		<CardLayout color={color}>
			<Flex flexDirection="column">
				{svg}
				<div className="user-details">
					<Avatar
						src={avatarURL}
						width="2.5rem"
						height="2.5rem"
						marginRight="10px"
					/>
					<div>{username}</div>
				</div>
				<Text fontSize={13} maxWidth="250px">
					{label}
				</Text>
			</Flex>
		</CardLayout>
	);
};

const CardColumn = () => {
	return (
		<Container padding="0" margin="0 2rem 0 0" className="cardColumn">
			{_shuffle(cardData).map((item, index) => (
				<Card {...item} key={index} />
			))}
		</Container>
	);
};

const Thoughts = () => {
	useAos(1000);

	return (
		<ThoughtsLayout>
			<div data-aos="fade-up" className="thoughtsWrapper">
				<Container padding="0 1rem" margin="0" maxWidth="600px">
					{["Explore a World", "of Thoughts and", "get Connected"].map(
						(item, index) => (
							<div
								data-aos="fade-up"
								className="heading"
								key={index}
							>
								{item}
							</div>
						)
					)}

					<p className="paragraph">{paragraph}</p>
				</Container>

				<div className="animated-container-wrapper">
					<AnimatedContainer>
						{new Array(3).fill(0).map((_, index) => (
							<CardColumn key={index} />
						))}
					</AnimatedContainer>
				</div>
			</div>
		</ThoughtsLayout>
	);
};

export default Thoughts;
