import React from "react";
import { Navbar } from "../../components/global/navbar/navbar";
import IcoFaq from "../../components/icoComponent/faq/IcoFaq";
import { Footer } from "../../components/home/footer/footer";

import { JWListLayout } from "./styled";

export default function JoinWaitList() {
	return (
		<>
			<Navbar />
			<JWListLayout>
				<div className="">
					<iframe
						src="https://docs.google.com/forms/d/e/1FAIpQLScN4X1L0eHfQqHcFhZGfIqaiIK_zJ4qcjR8C9VbxBaSjFJZTw/viewform?embedded=true"
						width="800"
						height="2200"
						frameborder="0"
						marginheight="0"
						marginwidth="0"
					>
						Loading…
					</iframe>
				</div>
			</JWListLayout>
			<IcoFaq />
			<Footer />
		</>
	);
}
