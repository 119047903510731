import styled from "styled-components";
import { constants } from "../../../constants/constants";


export const BlogLayout = styled.section`
  font-family: Poppins,sans-serif;

  .textWrapper {
      padding: 5rem 0 3rem 0;

      .gradientHeading {
          font-weight: bold;
          font-size: 68px;
          background: linear-gradient(to right, rgb(201,40,152), rgb(226,39,144));
          width: fit-content;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          line-height: 105px;
          line-height: 100px;
          width: max-content;
      }
  }

  .card-sec{
    background: black;
    color: white;
  }

  .card-bod{
    padding: 0;
  }

  .card-sub{
  margin-bottom: 10px;
  opacity: 0.4;
  }

  .card-title{
  margin-top: 2rem;
  }

  .card-des{
    font-size: 15px;
    opacity: 0.8;
  }

  .bottomSection{
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
  }
  .browse a{
    display: flex;
    white-space: nowrap;
    align-items: center;

    img{
    width: 15%;
    }
    p{
    margin: auto 10px;
    }
  }
  .controls{
    display: flex;
    span{
      cursor: pointer;
      img{
        width: 60%;
      }
    }
  }

  @media (max-width: ${ constants.breakpoints.mobile }) {
      .textWrapper {
          padding-bottom: 2rem;

          .gradientHeading {
              font-size: 35px;
              line-height: 45px;
          }
      }

.browse a {
  font-size: 14px;

  img{
    width: 10%;
  }
}

.controls{
  span{
    img{
      width: 50%;
    }
  }
}

  }
`
