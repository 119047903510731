import styled from "styled-components";

export const JWListLayout = styled.div`
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: end;
	font-family: "Poppins", sans-serif !important;
	margin-top: 10rem;

	.button button {
		padding: 8px 20px;
		color: #df2791;
		background-color: inherit;
		transition: all 0.3s ease;
	}
`;
