import { Fragment } from "react"
import { ChakraProvider } from "@chakra-ui/react"
import { Routes } from "./routes/routes"
import { GlobalStyles } from "./styles/global.styles"
import "bootstrap/dist/css/bootstrap.min.css"
import "aos/dist/aos.css"

const App= ( ) => {
    return (
        <Fragment>
            <GlobalStyles />
            <ChakraProvider>
                <Routes />
            </ChakraProvider>
        </Fragment>
    )
}

export default App