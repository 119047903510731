import styled from "styled-components";
import { constants } from "../../constants/constants";

export const PrivateSaleCont = styled.div`
	height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	text-align: center;

	h1 {
		margin: 3rem 0;
		font-weight: 600;
		font-size: 5rem;
		background: linear-gradient(
			to right,
			rgb(208, 40, 155),
			rgb(249, 104, 119)
		);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	p {
		margin: 1rem auto;
		text-align: center;
		max-width: 50rem;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		margin: 4vh 0 7vh 0;
		h1 {
			font-size: 3rem;
		}
	}
`;

export const RegisterBtn = styled.button`
	margin: 1rem 3rem;
	padding: 0.5rem 1.5rem;
	font-size: 1.5rem;
	background: linear-gradient(to right bottom, #d445ba, #e7a650);
	border-radius: 5px;
	transition: transform 0.3s ease-in-out;

	&:hover {
		transform: scale(1.1);
		background: linear-gradient(to right bottom, #40c9ff, #e81cff);
	}
`;
