import React from "react";
import { PricingLayout } from "./styles";
import { useAos } from "../../../hooks/useAos";
import { Link } from "react-router-dom";

export default function Pricing() {
	useAos(1000);

	return (
		<PricingLayout>
			<h1
				className="priceHeader grad-head"
				data-aos="fade-up"
				data-aos-duration="2000"
			>
				Sale Options
			</h1>
			<p className="mb-5" data-aos="fade-up" data-aos-duration="2000">
				If you want to take part in a Private Sale?{" "}
				<Link to="/private-sale" className="click-here">
					Click here
				</Link>
			</p>
			<div
				className="row d-flex justify-content-evenly "
				data-aos="fade-up"
				data-aos-duration="2000"
			>
				<div className="col-md-2 p-0 d-md-block d-none">
					<div style={{ padding: "1rem 0", margin: "10px 7px" }}>
						<div className="priceContent priceHead ">Features</div>
						<div className="priceContent">Tokens</div>
						<div className="priceContent">Supply for Sale</div>
						<div className="priceContent">Sale Period </div>
						<div className="priceContent">Fixed Sale Price</div>
						<div className="priceContent">Purchase Limits</div>
						<div className="priceContent">Funding Methods</div>
						<div className="priceContent">Lockup and Release</div>
						<br />
						<br />
						<div className="priceContent">
							Eligible Participants
						</div>
						<div
							className="priceContent"
							style={{ marginTop: "12rem" }}
						>
							Buy tokens
						</div>
					</div>
				</div>
				<div className="col-md-3  col-12 p-0">
					<div className="priceCard">
						<div className="priceHead text-center">Option 1 </div>
						<div className="priceContent">THZ Tokens </div>
						<div className="priceContent">
							Supply Sale Upto 18 Million
						</div>
						<div className="priceContent">
							TBA
						</div>
						<div className="priceContent">$0.06</div>
						<div className="priceContent">
							$500 Min - $10,000 Max
						</div>
						<div className="priceContent">BTC, ETH, USDT, BUSD</div>
						<div className="priceContent">
							12% vesting at listing, followed by 3 equal
							quarterly unlocks for the next 9 months
						</div>
						<div className="priceContent">
							Users who do not reside in the AFGHANISTAN, ALBANIA,
							BELARUS, BURMA, CENTRAL AFRICAN REPUBLIC, CONGO,
							CUBA, IRAN, IRAQ, LIBYA, LIBERIA, NORTH KOREA,
							PUERTO RICO, SOMALIA, SOUTH SUDAN, SUDAN, SYRIA,
							UKRAINE, USA, YEMEN, ZIMBABWE, US NON-ACCREDITED
							INVESTORS
						</div>
						<div className="priceContent">
							<button>$0.06</button>
							<br />
							<br />
							<button
								className="priceHead"
								style={{ width: "100%", textAlign: "center" }}
							>
								<Link to="/whitelist" target="_blank">
									Join Whitelist
								</Link>
							</button>
						</div>
					</div>
				</div>
				<div className="col-md-3  col-12 p-0">
					<div className="priceCard">
						<div className="priceHead text-center">Option 2 </div>
						<div className="priceContent">THZ Tokens</div>
						<div className="priceContent">
							Supply Sale Upto 27 Million
						</div>
						<div className="priceContent">
							TBA
						</div>
						<div className="priceContent">$0.08</div>
						<div className="priceContent">
							$400 Min - $10,000 Max{" "}
						</div>
						<div className="priceContent">
							BTC, ETH, USDT, BUSD{" "}
						</div>
						<div className="priceContent">
							16% vesting at listing, followed by 3 equal
							quarterly unlocks for the next 9 months
						</div>
						<div className="priceContent">
							Users who do not reside in the AFGHANISTAN, ALBANIA,
							BELARUS, BURMA, CENTRAL AFRICAN REPUBLIC, CONGO,
							CUBA, IRAN, IRAQ, LIBYA, LIBERIA, NORTH KOREA,
							PUERTO RICO, SOMALIA, SOUTH SUDAN, SUDAN, SYRIA,
							UKRAINE, USA, YEMEN, ZIMBABWE, US NON-ACCREDITED
							INVESTORS
						</div>
						<div className="priceContent">
							<button>$0.08</button>
							<br />
							<br />
							<button
								className="priceHead"
								style={{ width: "100%", textAlign: "center" }}
							>
								<Link to="/whitelist" target="_blank">
									Join Whitelist
								</Link>
							</button>
						</div>
					</div>
				</div>
				<div className="col-md-3  col-12 p-0">
					<div className="priceCard">
						<div className="priceHead text-center">Public Sale</div>
						<div className="priceContent">THZ Tokens</div>
						<div className="priceContent">
							Supply Sale Upto 27 Million
						</div>
						<div className="priceContent">
							TBA
						</div>
						<div className="priceContent">$0.12</div>
						<div className="priceContent">$200 Min - $1000 Max</div>
						<div className="priceContent">BTC, ETH, USDT, BUSD</div>
						<div className="priceContent">
							30% vesting at listing, followed by 5 months equal
							vesting
						</div>
						<br />
						<div className="priceContent">
							Users who do not reside in the AFGHANISTAN, ALBANIA,
							BELARUS, BURMA, CENTRAL AFRICAN REPUBLIC, CONGO,
							CUBA, IRAN, IRAQ, LIBYA, LIBERIA, NORTH KOREA,
							PUERTO RICO, SOMALIA, SOUTH SUDAN, SUDAN, SYRIA,
							UKRAINE, USA, YEMEN, ZIMBABWE, US NON-ACCREDITED
							INVESTORS
						</div>
						<div className="priceContent">
							<button>$0.12</button>
							<br />
							<br />
							<button
								className="priceHead"
								style={{ width: "100%", textAlign: "center" }}
							>
								<Link to="/whitelist" target="_blank">
									Join Whitelist
								</Link>
							</button>
						</div>
					</div>
				</div>
			</div>
		</PricingLayout>
	);
}
