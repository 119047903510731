export const data = [
	{
		label: "8%",
		id: "Seed",
		value: 72000000,
		color: "hsl(11, 70%, 50%)",
	},
	{
		label: "14%",
		id: "Private",
		value: 126000000,
		color: "hsl(201, 70%, 50%)",
	},
	{
		label: "2%",
		id: "Public",
		value: 18000000,
		color: "hsl(252, 70%, 50%)",
	},
	{
		label: "8%",
		id: "Marketing",
		value: 72000000,
		color: "hsl(257, 70%, 50%)",
	},
	{
		label: "24%",
		id: "Rewards pool",
		value: 216000000,
		color: "hsl(11, 70%, 50%)",
	},
	{
		label: "20%",
		id: "Development",
		value: 180000000,
		color: "hsl(182, 70%, 50%)",
	},
	{
		label: "12%",
		id: "Team",
		value: 108000000,
		color: "hsl(250, 70%, 50%)",
	},
	{
		label: "2%",
		id: "Advisors",
		value: 18000000,
		color: "hsl(11, 70%, 50%)",
	},
	{
		label: "10%",
		id: "Treasury",
		value: 90000000,
		color: "hsl(169, 70%, 50%)",
	},
];

// export  data;
