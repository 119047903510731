import React from "react";
import { PrivateSaleTableLayout } from "./styles";
import { tableData } from "./tableData";
import { useAos } from "../../../hooks/useAos";
import { constants } from "../../../constants/constants";
import { useMediaQuery } from "react-responsive";

export default function TokenAllocation() {
	useAos();

	const isIpad = useMediaQuery({
		query: `(max-width: ${constants.breakpoints.ipad})`,
	});

	return (
		<>
			<PrivateSaleTableLayout>
				<div
					className="row w-100"
					data-aos="fade-up"
					data-aos-duration="1500"
				>
					<div className="col-md-5 col-12">
						<div className="labelCard">
							<h5>Token name</h5>
							<h1>THZ</h1>
						</div>
					</div>
					<div className="col-md-7 col-12">
						<div className="labelCard">
							<h5>Token Supply</h5>
							<h1>900,000,000</h1>
						</div>
					</div>
				</div>

				{!isIpad && (
					<div
						className="tableLayout"
						data-aos="fade-up"
						data-aos-duration="2000"
					>
						<table>
							<thead>
								<tr>
									<th>Allocation</th>
									<th>Percent</th>
									<th isNumeric>Tokens</th>
									<th>Token Price</th>
									<th isNumeric>Vesting Period</th>
								</tr>
							</thead>
							<tbody>
								{tableData.map((data, index) => (
									<tr key={index}>
										<td
											style={{
												fontWeight: "600",
											}}
										>
											{data.label}
										</td>
										<td>{data.percent}</td>
										<td>{data.tokens}</td>
										<td>{data.tokenPrice}</td>
										<td>{data.vesting}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}

				{isIpad &&
					tableData.map((data, index) => (
						<div
							className="cardLayout"
							data-aos="fade-up"
							data-aos-duration="1500"
						>
							<div className="cardLayout0">
								<h1>{data.label}</h1>
								<p>
									Percent: <span>{data.percent}</span>
								</p>
								<p>
									Tokens: <span>{data.tokens}</span>
								</p>
								<p>
									Token price: <span>{data.tokenPrice}</span>
								</p>
								<p>
									Vesting Period: <span>{data.vesting}</span>
								</p>
							</div>
						</div>
					))}
			</PrivateSaleTableLayout>
		</>
	);
}
