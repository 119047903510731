import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const Table = styled.div`
	margin: 5rem auto;
	width: 80%;
	height: fit-content;
	border: 1px solid #fff;
	border-top-right-radius: 1rem;
	border-top-left-radius: 1rem;
	border-bottom-right-radius: 1rem;

	@media (max-width: ${constants.breakpoints.desktop}) {
		margin-top: -5rem;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		margin: 0 0 5rem 0;
		width: 100%;
		border: 3px solid #fff;
		margin-top: -5rem;
	}

	.chart-table {
		padding: 2rem 1rem;
		list-style: none;
		background-color: #1f1f1f;
		border-top-right-radius: 1rem;
		border-top-left-radius: 1rem;
		border-bottom-right-radius: 1rem;
	}

	.table-logo {
		margin-bottom: 1rem;
		display: flex;
		align-items: center;
		font-size: 2rem;
		color: #fff;
		border: none;
	}

	.chart-table li {
		padding-top: 0.5rem;
		display: flex;
		justify-content: space-between;
		color: #fff;
		border: none;
	}

	.left-li {
		display: flex;
		margin-right: 0.5rem;
		span {
			width: 60px;
		}
	}

	/* .chart-table li span {
		margin-right: 1.5rem;
		@media (max-width: ${constants.breakpoints.mobile}) {
			margin-right: 0.5rem;
		}
	} */

	.span1 {
		color: #ecc1a3;
	}

	.span2 {
		color: #ecc1a3;
	}

	.span3 {
		color: #fe7665;
	}

	.span4 {
		color: #f4e26a;
	}

	.span5 {
		color: #efa948;
	}

	.span6 {
		color: #48cdbb;
	}

	.span7 {
		color: #8be3d5;
	}
`;
