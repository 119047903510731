import styled from "styled-components";
import { constants } from "../../../constants/constants";
import { Flex } from "@chakra-ui/react";

export const TeamLayout = styled.section`
	.heading {
		background: linear-gradient(
			to right,
			rgb(208, 40, 155),
			rgb(249, 104, 119)
		);
		background-size: cover;
		background-position: center;
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		font-size: 60px;
		font-weight: 600;
		line-height: 70px;
		margin-bottom: 3.5rem;

		@media (max-width: ${constants.breakpoints.mobile}) {
			font-size: 35px;
			line-height: 45px;
			font-weight: 700;
		}
	}
`;

export const RowLayout = styled(Flex)`
	justify-content: space-evenly;

	@media (min-width: ${constants.breakpoints.mobile}) {
		&:not(:first-child) {
			margin-top: 5rem;
		}
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		flex-direction: column;
		align-items: center;
	}
`;

export const TeamPersonLayout = styled.div`
	background: linear-gradient(
			to bottom left,
			transparent,
			transparent,
			rgba(15, 18, 22, 0.5)
		),
		url("${({ url }) => url}");
	background-size: cover;
	background-position: top;
	border-radius: 3px;
	width: 23%;
	min-width: 250px;
	height: 20rem;
	position: relative;

	margin-top: ${({ index }) => {
		switch (index) {
			case 1:
				return "3rem";

			case 2:
				return "-3.5rem";

			default:
				return "0";
		}
	}};

	@media (max-width: ${constants.breakpoints.mobile}) {
		height: 20rem;
	}
	/*
	&:hover {
		@media (min-width: ${constants.breakpoints.mobile}) {
			background-image: linear-gradient(
					to bottom left,
					rgba(54, 53, 51, 0.4),
					rgba(15, 18, 22, 0.8),
					rgba(15, 18, 22, 0.9)
				),
				url("${({ url }) => url}");

			.team-person-quoted {
				visibility: visible;
				opacity: 1;
			}
		}
	} */

	.team-person-details {
		position: absolute;
		bottom: -30px;
		left: -30px;
		width: 380px;
		display: flex;
		justify-content: space-between;

		.linkedInIco {
			position: absolute;
			right: -45px;
			bottom: 15px;
		}
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		margin-top: 1rem;
		width: 90%;
		margin-bottom: 3.5rem;

		.team-person-details {
			left: -10px;
			width: 330px;

			.linkedInIco {
				right: -25px;
			}
		}
	}
`;

export const TeamPersonLayout1 = styled.div`
	background: linear-gradient(
			to bottom left,
			transparent,
			transparent,
			rgba(15, 18, 22, 0.5)
		),
		url("${({ url }) => url}");
	background-size: cover;
	background-position: top;
	border-radius: 3px;
	width: 23%;
	min-width: 250px;
	height: 20rem;
	position: relative;

	margin-top: ${({ index }) => {
		switch (index) {
			case 1:
				return "3rem";

			case 2:
				return "-3.5rem";

			default:
				return "0";
		}
	}};

	@media (max-width: ${constants.breakpoints.mobile}) {
		height: 20rem;
	}
	/*
	&:hover {
		@media (min-width: ${constants.breakpoints.mobile}) {
			background-image: linear-gradient(
					to bottom left,
					rgba(54, 53, 51, 0.4),
					rgba(15, 18, 22, 0.8),
					rgba(15, 18, 22, 0.9)
				),
				url("${({ url }) => url}");

			.team-person-quoted {
				visibility: visible;
				opacity: 1;
			}
		}
	} */

	.team-person-details {
		position: absolute;
		bottom: -30px;
		left: -30px;
		width: 380px;
		display: flex;
		justify-content: space-between;

		.linkedInIco {
			position: absolute;
			right: -45px;
			bottom: 15px;
		}
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		margin-top: 1rem;
		width: 90%;
		margin-bottom: 3.5rem;

		.team-person-details {
			left: -10px;
			width: 330px;

			.linkedInIco {
				right: -25px;
			}
		}
		&:nth-last-child(-n + 2) {
			display: none;
		}
	}
`;

export const AdvisorLayout = styled.div`
	background: linear-gradient(
			to bottom left,
			transparent,
			transparent,
			rgba(15, 18, 22, 0.5)
		),
		url("${({ url }) => url}");
	background-size: cover;
	background-position: top;
	border-radius: 3px;
	width: 23%;
	min-width: 250px;
	height: 20rem;
	position: relative;

	.team-person-details {
		position: absolute;
		bottom: -30px;
		left: -30px;
		width: 380px;
		display: flex;
		justify-content: space-between;

		.linkedInIco {
			position: absolute;
			right: -45px;
			bottom: 15px;
		}
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		margin-top: 1rem;
		width: 90%;
		margin-bottom: 3.5rem;

		.team-person-details {
			left: -10px;
			width: 330px;

			.linkedInIco {
				right: -25px;
			}
		}

		&:last-child {
			display: none;
		}
	}
`;

export const QuotesLayout = styled.div`
	max-width: 275px;
	transition: all 0.6s;
	margin: 0 0 4rem -55px;

	@media (min-width: ${constants.breakpoints.mobile}) {
		visibility: hidden;
		opacity: 0;
		left: -30px;
		position: absolute;
		top: 15px;
		margin: 0;
	}

	.quote-heading {
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 2rem;
		margin-bottom: 2rem;
	}

	.quote-description {
		p {
			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}

	.skills {
		margin-top: 1rem;
		display: flex;
		flex-direction: column;

		p {
			&:not(:first-child) {
				margin-top: 3px;
			}
		}
	}
`;
