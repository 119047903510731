import styled from "styled-components";
import { Flex } from "@chakra-ui/react";
import { constants } from "../../../constants/constants";

export const PlatformsLayout = styled(Flex)`
	background: url("/images/background.jpg");
	background-size: cover;
	background-position: center;
	height: 75vh;
	width: 100vw;
	max-width: 1400px;
	justify-content: center;
	align-items: center;
	padding: 0 15px;

	img {
		height: 90%;
		margin-right: 10%;
		/* transform: rotate(3deg) skew(25deg);
		border-radius: 1rem;
		background: #000;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
	}

	.download-button {
		&:first-child {
			margin-right: 1.5rem;
		}
	}

	.heading {
		font-size: 60px;
		font-weight: 700;
		/* max-width: 500px; */
		line-height: 75px;
		margin-bottom: 2rem;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		flex-direction: column-reverse;
		/* height: 80vh; */
		padding-top: 5rem;

		img {
			margin: 5rem 0 0 0;
		}

		.download-button {
			&:first-child {
				margin-right: 1rem;
			}
		}

		.heading {
			font-size: 45px;
			line-height: unset;
		}
	}
`;
