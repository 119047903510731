import { ReactComponent as SVG1 } from "../../../svg/svg-1.svg";
import { ReactComponent as SVG2 } from "../../../svg/svg-2.svg";
import { ReactComponent as SVG3 } from "../../../svg/svg-3.svg";
import { ReactComponent as SVG4 } from "../../../svg/svg-4.svg";

export const paragraph =
	"Our specialized teams lead the product design and development process for high growth companies.";

export const contentArray = [
	{
		svg: <SVG1 width="82px" height="97px" />,
		number: "01",
		heading: "Token-transfer At The Snap Of A Finger",
		description:
			"The instant transfer protocol utilized by this platform is turning up the speed of token transfer, up a notch. Users will be credited instantly with THOUGHTZ (THZ) coins as soon as they bought them.",
	},
	{
		svg: <SVG2 width="82px" height="97px" />,
		number: "02",
		heading: "Referral Rewards",
		description:
			"Users can treat themselves to a few more tokens in addition to the ones they've already purchased. Users may utilize the referral link to share it with their friends and knowns and receive additional tokens if they utilize the link.",
	},
	{
		svg: <SVG3 width="90px" height="97px" />,
		number: "03",
		heading: "KYC",
		description:
			"THOUGHTZ has added a new checkpoint to pass in order to conduct legitimate fundraising: Know Your Customer. KYC is used to get to know your customers a bit better and make fundraising a little more reliable.",
	},
	{
		svg: <SVG4 width="80px" height="97px" />,
		number: "04",
		heading: "Mode Of Purchase",
		description:
			"THOUGHTZ coins may be purchased by paying with your preferred cryptocurrency. Customers may acquire THOUGHTZ (THZ) coins using BTC, ETH, USDT, BUSD and a flexible payment methodology has been provided to the ICO platform for users' preferred cryptos.",
	},
];
