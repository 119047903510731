import { Flex } from "@chakra-ui/react";
import { constants } from "../../../constants/constants";
import styled from "styled-components";

export const LayoutWrapper = styled(Flex)`
	margin-top: 2rem;

	.video {
		width: 50%;
		video {
			max-width: 350px;
			/* transform: skewX(-10deg) skewY(5deg) translateX(-50px); */
			margin-top: 35px;
		}
	}

	.sectionLabel {
		letter-spacing: 5.5px;
		margin-bottom: 2.5rem;
		font-size: 14px;
		font-weight: 500;
	}

	.headingLayout {
		margin-bottom: 2rem;

		.gradientHeading {
			font-weight: 600;
			font-size: 60px;
			background: linear-gradient(
				to right,
				rgb(201, 40, 152),
				rgb(226, 39, 144)
			);
			width: fit-content;
			-webkit-text-fill-color: transparent;
			background-clip: text;
			-webkit-background-clip: text;
			line-height: 70px;
		}
	}

	.featuresWrapper {
		display: flex;

		&:last-child {
			margin-top: 3rem;
		}
	}

	@media (max-width: ${constants.breakpoints.ipad}) {
		flex-direction: column-reverse;

		.video {
			width: 100%;
			video {
				margin-bottom: 4.5rem;
				max-width: 250px;
				/* transform: skewX(-10deg) skewY(5deg) translateX(75px); */
			}
		}

		.sectionLabel {
			letter-spacing: 3px;
			margin-bottom: 1rem;
		}

		.headingLayout {
			margin-bottom: 5rem;

			.gradientHeading {
				font-size: 50px;
				line-height: 70px;
			}
		}

		.featuresWrapper {
			flex-direction: row;
			margin-top: 2.5rem;
		}
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		.featuresWrapper {
			flex-direction: column;
			margin-top: 2.5rem;
		}
	}
`;

export const FeaturesLayout = styled.div`
	padding-top: 2rem;
	position: relative;
	border-top: 1px solid rgb(79, 79, 79);

	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		border-top: 1px solid white;
		transition: all 0.3s;
	}

	&:hover {
		&:after {
			width: 100%;
		}
	}

	&:first-child {
		margin-bottom: 2.5rem;
	}

	.heading {
		margin-top: 10px;
		font-size: 25px;
	}

	.description {
		max-width: 300px;
		margin-top: 10px;
		line-height: 1.5rem;
		color: grey;
	}

	img {
		margin: 0 0 1rem 0.5rem;
		width: 3rem;
		height: 3rem;
		transform: none;
	}

	@media (min-width: ${constants.breakpoints.ipad}) {
		&:first-child {
			margin-right: 4rem;
			margin-bottom: 0;
		}

		.paragraph {
			max-width: unset;
		}
	}
`;
