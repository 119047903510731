import styled from "styled-components";

export const JobsLayout = styled.div`
	min-height: 100vh;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* background: red; */

	.heading {
		.head {
			font-size: 1.3rem !important;
			transition: all 0.5s ease;
		}
	}

	:root {
		--small-heading: 13px;
	}
	.jobs-main-container {
		padding-top: 100px;
		background: var(--secondary-color);
	}
	.job-header-main-container {
		width: 100%;
		background: rgba(0, 0, 0, 0.7);
		position: sticky;
		margin-top: 50px;
		/* position: -webkit-sticky; */
		top: 63px;
		padding: 3rem 0;
		/* height: 100px; */
		transition: all 0.5s ease;
	}
	.job-header-main-container.none {
		opacity: 0;
		pointer-events: none;
		font-size: var(--small-heading);
	}
	.job-header-main-container.small {
		font-size: var(--small-heading);
	}
	.job-header-container {
		width: 80%;
		margin: 0 auto;
		transition: all 0.5s ease;
		display: flex;
	}
	.job-header-container .head {
		flex: 4;
		font-weight: 500;
		font-size: 2rem;
		color: #df2791;
	}
	.job-header-container .button {
		text-align: right;
		flex: 1;
	}
	.job-header-container .button button {
		padding: 8px 20px;
		/* font-weight: bolder; */
		color: #df2791;
		border: 2px solid #df2791;
		border-radius: 5px;
		background-color: inherit;
		transition: all 0.3s ease;
	}
	.job-header-container .button button:hover {
		background: #df2791;
		color: #fff;
	}
	@media screen and (max-width: 825px) {
		.job-header-container {
			display: block;
		}
		.job-header-container .button {
			text-align: left;
			margin-top: 20px;
		}
	}
	.job-des-container {
		width: 80%;
		margin: 40px auto;
		margin-top: 150px;
	}
	.job-des-container .content {
		margin: 60px 0;
		color: #808080;
	}
	.job-des-container .content h3 {
		margin-bottom: 30px;
		letter-spacing: 5.5px;
		font-size: 18px;
		font-weight: 500;
		color: #fff;
	}
	.job-des-container .content ul {
		margin-left: 16px;
	}
	.job-des-container .content ul li {
		margin: 10px 0;
	}
	.job-contact-form {
		padding: 5rem 0;
		display: flex;
		width: 80%;
		margin: 2rem auto;
	}
	@media screen and (max-width: 769px) {
		.job-contact-form {
			flex-direction: column;
		}
	}
	.formContainer {
		background: rgb(7, 7, 7);
		width: 100%;
	}
	.job-contact-form .title {
		flex: 1;
		margin-bottom: 40px;
	}
	.job-contact-form .form {
		flex: 2;
	}
	.job-contact-form .form .input-fields {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}
	.job-contact-form .form .input-fields label {
		font-size: 1rem;
	}
	.job-contact-form .form .input-fields input, textarea {
		width: 400px;
		border: none;
		margin-top: 12px;
		background: transparent;
		border-bottom: 1px solid #fff;
	}
	@media screen and (max-width: 500px) {
		.job-contact-form .form .input-fields input {
			width: 100%;
		}
	}
	.job-contact-form .form .input-fields input[type="file"] {
		border: none;
		display: none;
	}
	.resume-lable-input {
		cursor: pointer;
		width: 100px;
		background: #ccc;
		margin-top: 10px;
		text-transform: uppercase;
		padding: 10px 15px;
	}
	.job-contact-form .form button {
		background: linear-gradient(to right bottom, #d445ba, #e7a650);
		border: none;
		padding: 10px 20px;
		border-radius: 5px;
		font-weight: 600;

		&:hover {
			background: linear-gradient(to right bottom, #40c9ff, #e81cff);
			transform: scale(1.05);
			transition: 0.3s;
		}
	}
`;
