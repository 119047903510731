import styled from "styled-components";
import { constants } from "../../../constants/constants";
import { Flex } from "@chakra-ui/react";

export const ReviewsLayout = styled.div`
	max-width: 1400px;
	padding: 25rem 1rem;
	background: ${constants.theme.background};
	width: 100%;

	.carouselWrapper {
		background: url("/images/background.jpg");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		padding: 15px;
		position: relative;
		max-width: 1200px;
		margin: 0 auto;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		padding: 10rem 1rem 12rem 1rem;

		.carouselWrapper {
			padding: 10px;
		}
	}
`;

export const ReviewDetailsLayout = styled(Flex)`
	height: 50vh;
	background: ${constants.theme.background};
	padding: 3rem;
	margin: 0;
	max-width: unset;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.paragraph {
		font-size: 1.8rem;
		font-weight: 600;
		margin-bottom: 2rem;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		padding: 3rem 1rem 4rem 1rem;

		.paragraph {
			font-size: 15px;
			line-height: 1.4rem;
		}
	}
`;

export const QuoteSVGContainer = styled.div`
	padding: 0;
	margin: 0;
	position: absolute;
	width: 100px;
	height: 80px;

	top: ${({ position }) => position === "top" && "-48px"};
	bottom: ${({ position }) => position === "bottom" && "-48px"};

	left: ${({ position }) => position === "top" && "40px"};
	right: ${({ position }) => position === "bottom" && "40px"};

	background: url("/images/background.jpg");
	background-size: cover;
	background-attachment: fixed;

	background-position: ${({ position }) => {
		if (position === "top") {
			return "center";
		} else return "left";
	}};
`;
