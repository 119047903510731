export const constants = {
	breakpoints: {
		mobile: "480px",
		ipad: "1024px",
		desktop: "1280px",
	},

	theme: {
		background: "black",
	},
};
