import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const DescriptionLayout = styled.section`
	.section-label {
		letter-spacing: 5.5px;
		margin-bottom: 2.5rem;
		font-size: 14px;
		font-weight: 500;
	}

	.paragraph {
		font-size: 50px;
		font-weight: 600;
		margin-top: 1rem;
	}

	.cardRow {
		margin-top: 7rem;
		display: flex;
		justify-content: space-between;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		.section-label {
			letter-spacing: 3px;
			margin-bottom: 1rem;
		}

		.paragraph {
			font-weight: normal;
			font-size: 1.4rem;
		}

		.cardRow {
			flex-direction: column;
			margin-top: 7.5rem;
		}
	}
`;

export const RowitemLayout = styled.div`
	padding: 0 5px;
	max-width: 350px;
	position: relative;
	transition: all 0.6s ease-in;

	svg {
		margin-bottom: 3.5rem;
	}

	circle,
	rect {
		transition: all 0.4s;
	}

	#svg-1 circle {
		transform-origin: 64.5px 18.5px;
	}

	#svg-3 circle {
		transform-origin: 45px 45px;
	}

	#svg-4 rect {
		transform-origin: 39.5px 50.5px;
	}

	&:hover {
		#svg-1 circle {
			&:last-child {
				transform: scale(1.714);
			}
		}

		#svg-2 circle {
			&:first-child {
				transform: translateY(15px);
			}

			&:last-child {
				transform: translateY(-15px);
			}
		}

		#svg-3 circle {
			&:first-child {
				transform: scale(2.1538);
			}

			&:last-child {
				transform: scale(0.6512);
			}
		}

		#svg-4 rect {
			&:first-child {
				transform: translate(-15px, 16px);
			}

			&:last-child {
				transform: translate(16px, -15px);
			}
		}
	}

	.label {
		color: #9c9ea5;
		margin-bottom: 1.5rem;
	}

	.heading,
	.heading1 {
		font-weight: 600;
		font-size: 1.5rem;
		margin-bottom: 1rem;
		padding: 0 10px;
	}

	.description {
		margin-bottom: 7rem;
		padding: 0 10px;
		color: grey;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		.label {
			margin-bottom: 0.5rem;
		}

		.description {
			margin-bottom: 3.5rem;
		}

		svg {
			margin: 0 auto;
		}
	}
`;
