import { PlatformsLayout } from "./styles";
import { Container, Button, Flex, Text } from "@chakra-ui/react";
import { FaGooglePlay, FaAppStoreIos } from "react-icons/fa";
import { useAos } from "../../../hooks/useAos";

const DownloadButton = ({ icon, text }) => {
	return (
		<Button
			colorScheme="blackAlpha"
			color="white"
			fontWeight="400"
			padding="1.5rem 2rem"
			alignItems="center"
			display="flex"
			className="download-button"
		>
			<Flex>
				<Text marginRight="10px">{text}</Text>
				{icon}
			</Flex>
		</Button>
	);
};

const Platforms = () => {
	useAos(1000);

	return (
		<>
			{/* <Image className="" src="/images/flatiPhone.png" /> */}
			<PlatformsLayout id="download-section">
				<Container padding="0px" margin="0px">
					<p className="heading text-center" data-aos="fade-up">
						Coming soon on android and iOS
					</p>

					<Flex className="justify-content-center" data-aos="fade-up">
						<DownloadButton
							text="Play Store"
							icon={<FaGooglePlay size={20} />}
						/>

						<DownloadButton
							text="App Store"
							icon={<FaAppStoreIos size={20} />}
						/>
					</Flex>
				</Container>
			</PlatformsLayout>
		</>
	);
};

export default Platforms;
