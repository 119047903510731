import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const PricingLayout = styled.section`
	min-height: 90vh !important;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* border: 5px solid white; */
	border-radius: 10px;
	padding: 2rem 1rem;
	margin: 2rem 1rem;

	.grad-head {
		font-weight: 600;
		font-size: 6rem;
		background: linear-gradient(
			to right,
			rgb(208, 40, 155),
			rgb(249, 104, 119)
		);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		margin: 3rem 0;
	}

	.click-here {
		color: #d02896;
		text-decoration: underline;
	}

	.priceHead {
		margin: 1rem 0;
	}

	.priceCard {
		background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
		border-radius: 10px;
		padding: 1rem;
		margin: 10px 7px;
		font-size: 18px;
		.priceHead {
			margin: 1rem 0;
			border-radius: 10px;
			background-color: #000;
			padding: 12px 10px;
		}
		.priceContent {
			padding: 12.5px 10px;
			border-top: 1px solid rgb(180, 180, 180);
			font-size: 16px;
		}
	}
	.priceContent {
		padding: 13.5px 15px;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		.grad-head {
			font-size: 3rem;
		}
	}
`;
