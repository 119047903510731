export const accordionItems = [
	{
		question: "What is Thoughtz ?",
		answer: "Thoughtz is an app to connect with people who are thinking the same thoughts as you, at any given point of time. Using Thoughtz, you can either explore the most popular thoughts based on your location preferences or post a thought and connect with people having similar thoughts.",
	},
	{
		question: "How user earn rewards in Thoughtz?",
		answer: "With Thoughtz's blockchain-based reward system, you can receive tips in Thoughtz coins for sharing your expertise and creative ideas. It's a way to thank someone for making an exceptional contribution. Share to earn!",
	},
	{
		question: "What are Thought NFTs?",
		answer: "Thoughtz empowers users to create visually appealing and emotionally charged digital images, or NFTs, based on their own thoughts, expressions and ideas. It is a combination of thought, expression and art that makes it unique.You can exchange your unique creations and make transactions on the Thoughtz NFT marketplace.",
	},
	{
		question: "How experts can earn rewards in Thoughtz ?",
		answer: "Thoughtz is the currency that powers the Expert network. We are creating a platform where experts will be able to share their knowledge, ideas and opinions with an audience of like-minded people who are looking for that information. As an expert, you can set your rates for sharing your expertise. If people like what you're saying, they can reward you in thoughtz.",
	},
	{
		question: "Is there a group chat option?",
		answer: "Thoughtz has been developed to connect with users who are thinking the same thoughts as you at any given point of time, therefore this is restricted to individual interaction only, with an option to connect with as many users as you would like. However, we are constantly working on adding new features, so please stay tuned for more new features which includes group matching and chat option.",
	},
];
