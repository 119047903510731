import { Heading } from "@chakra-ui/react";
import { FeaturesLayout, LayoutWrapper } from "./styles";
import { features, features_2 } from "./data";
import { useAos } from "../../../hooks/useAos";

const Features = ({ svg, heading, description }) => {
	return (
		<FeaturesLayout>
			{/* {svg} */}
			<img src={svg} alt="features-logo" />
			<Heading className="heading">{heading}</Heading>
			<p className="description">{description}</p>
		</FeaturesLayout>
	);
};

export default function Concept() {
	useAos(1000);

	return (
		<section id="concept">
			<LayoutWrapper justifyContent="space-between">
				<div className="d-flex align-items-center justify-content-center">
					<div>
						<div data-aos="fade-up" className="sectionLabel">
							MAKING IDEAS REAL
						</div>
						<div data-aos="fade-up" className="headingLayout">
							<div className="gradientHeading">From Thoughts</div>
							<div className="gradientHeading">
								to Connections
							</div>
						</div>

						{[features, features_2].map((array, index) => (
							<div
								className="featuresWrapper"
								data-aos="fade-up"
								key={index}
							>
								{array.map((item, index) => (
									<Features {...item} key={index} />
								))}
							</div>
						))}
					</div>
				</div>
				<div className="d-flex justify-content-center align-items-end video">
					<video autoPlay loop muted playsInline>
						<source
							src="images/ThoughtzMockup.mp4"
							type="video/mp4"
						/>
					</video>
				</div>
			</LayoutWrapper>
		</section>
	);
}
