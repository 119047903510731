import { FAQLayout } from "./styles";
import {
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionPanel,
	AccordionIcon,
} from "@chakra-ui/react";
import { accordionItems } from "./data";
import { useAos } from "../../../hooks/useAos";

export default function IcoFaq() {
	useAos(1000);

	return (
		<FAQLayout id="faq" data-aos="fade-up">
			<div className="textWrapper">
				<p data-aos="fade-up" className="gradientHeading">
					FAQ'S
				</p>
			</div>

			<Accordion allowToggle>
				{accordionItems.map((item, index) => (
					<AccordionItem
						data-aos="fade-up"
						data-aos-duration="2000"
						border="none"
						key={index}
					>
						<AccordionButton
							_focus={{ boxShadow: "none" }}
							padding="20px 0"
							borderColor="transparent !important"
						>
							<AccordionIcon />
							<p className="question">{item.question}</p>
						</AccordionButton>

						<AccordionPanel>
							<p className="paragraph">{item.answer}</p>
						</AccordionPanel>
					</AccordionItem>
				))}
			</Accordion>
		</FAQLayout>
	);
}
