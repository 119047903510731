import blog1 from "../../../images/Blogs/blog1.jpeg"
import blog2 from "../../../images/Blogs/blog2.jpeg"
import blog3 from "../../../images/Blogs/blog3.jpeg"

export const BlogsData = [
  {
    title:"Thoughtz — Where Great Minds Meet!",
    subtitle: "",
    description:"The new generation of knowledge and thought sharing platform. Thoughtz is an upcoming blockchain project that aims to enable people to share their thoughts, ideas, and knowledge and reward them for those while instantly",
    img:blog1,
    link:"https://thoughtz.medium.com/introducing-thoughtz-where-great-minds-meet-a0822857c822"
  },
  {
    title:"Collective Intelligence (CI) and AI powering Thoughtz ($THZ)",
    subtitle: "",
    description:"Collective Intelligence (CI) and AI powering Thoughtz ($THZ) Till now, AI has been viewed as an alternate to do the tasks that can be made more",
    img:blog2,
    link:"https://thoughtz.medium.com/collective-intelligence-ci-and-ai-powering-thoughtz-thz-72f18bd6fb66"
  },
  {
    title:"Metaverse, AI and Blockchain",
    subtitle: "",
    description:"Metaverse The term “Metaverse” is a combination of ‘meta’ meaning ‘virtual, transcendence’ and ‘verse’ a backformation from ‘universe’. To talk in very simple terms, it is a combination of multiple elements of technology",
    img:blog3,
    link:"https://thoughtz.medium.com/metaverse-ai-and-blockchain-17d4a2d8865f"
  }
]
