import { useEffect, useRef, useState } from "react";
import * as jquery from "jquery";
import { HeaderLayout, TextWithBackgroundImage } from "./styles";

const Header = () => {
	const ref = useRef(null),
		[scrollPercent, setScrollPercent] = useState(0);

	useEffect(
		function () {
			if (ref.current) {
				jquery(window).on("scroll", function () {
					const windowScrollPosition = jquery(this).scrollTop(),
						childOffset = jquery(".header-layout").offset()?.top,
						limiter = ref.current?.clientHeight / 2;

					if (
						windowScrollPosition >= childOffset &&
						windowScrollPosition <= limiter + childOffset
					) {
						const percent =
							((windowScrollPosition - childOffset) / limiter) *
							100;
						setScrollPercent(percent);
					}
				});
			}
		},
		[ref]
	);

	return (
		<HeaderLayout className="header-layout" ref={ref}>
			<div className="header-parent-wrapper">
				<div className="header-wrapper">
					<div className="text-container d-sm-block d-none">
						<TextWithBackgroundImage
							backgroundPosition="center"
							textAlign="end"
							scrollPercent={scrollPercent}
						>
							Where
						</TextWithBackgroundImage>
					</div>

					<div className="text-container  d-sm-block d-none">
						<TextWithBackgroundImage
							backgroundPosition="bottom"
							textAlign="start"
							scrollPercent={scrollPercent}
						>
							thoughts
						</TextWithBackgroundImage>
					</div>

					<div className="text-container  d-md-block d-none">
						<TextWithBackgroundImage
							backgroundPosition="100%"
							textAlign="end"
							scrollPercent={scrollPercent}
						>
							meet
						</TextWithBackgroundImage>
					</div>

					{/* Mobile */}
					<div className="text-container d-md-none d-blick">
						<TextWithBackgroundImage
							backgroundPosition="center"
							textAlign="end"
							// scrollPercent={scrollPercent}
						>
							Where
						</TextWithBackgroundImage>
					</div>

					<div className="text-container  d-sm-none d-block">
						<TextWithBackgroundImage
							backgroundPosition="bottom"
							textAlign="start"
							// scrollPercent={scrollPercent}
						>
							thoughts
						</TextWithBackgroundImage>
					</div>

					<div className="text-container  d-sm-none d-block">
						<TextWithBackgroundImage
							backgroundPosition="100%"
							textAlign="end"
							// scrollPercent={scrollPercent}
						>
							meet
						</TextWithBackgroundImage>
					</div>
				</div>
			</div>
		</HeaderLayout>
	);
};

export default Header;
