export const cardData = [
	{
		uri: "/images/mockup/Screen1.png",
		heading: "Create your own NFTs that are backed by a Thought or Idea",
		paragraph:
			"Start expressing yourself through digital art by creating an NFT from your favorite video game pixel, joke, picture, or whatever you want.",
	},
	{
		uri: "/images/mockup/Screen2.png",
		heading: "NFT Marketplace",
		paragraph:
			"Thoughtz NFT Marketplace provides the best features for content creators to mint, list, and sell their thoughts as NFT.",
	},
	{
		uri: "/images/mockup/Screen3.png",
		heading: "Expert Marketplace",
		paragraph:
			"Instantly get connected with knowledge experts based on your thoughts. Thoughtz makes it easy for knowledge experts to share their opinion and be rewarded.",
	},
];
