export const accordionItems = [
	{
		question: "Where can I buy Thoughtz tokens ?",
		answer: "Thoughtz has developed its own token purchase platform. In the sale options, please select your option and click buy to purchase Thoughtz tokens. It will redirect to the platform for registration and once registered, user can purchase the tokens.",
	},
	{
		question: "Can I participate in all three Sale options ?",
		answer: "Yes, users can choose to participate in all the three sale options. For a discounted price, we also offer private sale for early investors. For private sale option, please select the private sale option from the menu and register.",
	},
	{
		question: "Any bonus programs to exploit ?",
		answer: "Thoughtz platform has a referral program where users can forward the referral code from their login account to any of their knowns. On redeeming the code, a certain percentage of the bought coins will be credited to the sharer’s wallet.",
	},
	{
		question: "What cryptocurrencies are accepted to buy Thoughtz coins ?",
		answer: "We accept, BTC, ETH, USDT and BUSD.",
	},
	{
		question: "How do I know more about the Thoughtz project ?",
		answer: "On the THZ token sale page, you will find the whitepaper option. Selecting it will take you to the official whitepaper where you can acquaint yourself with every little detail about the project.",
	},
];
