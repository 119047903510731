import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const AboutLayout = styled.section`
	margin-bottom: 3.5rem;

	.section-label {
		letter-spacing: 5.5px;
		margin-bottom: 2.5rem;
		font-size: 14px;
		font-weight: 500;
	}

	.wrapper {
		display: flex;

		.content-wrapper {
			max-width: 45%;
			padding-right: 1rem;

			.heading {
				&:first-child {
					margin-top: 5rem;
				}

				background: linear-gradient(
					to right,
					rgb(208, 40, 155),
					rgb(249, 104, 119)
				);
				background-size: cover;
				background-position: center;
				-webkit-text-fill-color: transparent;
				-webkit-background-clip: text;
				background-clip: text;
				font-size: 60px;
				font-weight: 600;
				line-height: 70px;
			}

			.sub-heading {
				font-size: 1.3rem;
				margin-top: 1.5rem;
			}

			.paragraph {
				margin-top: 3rem;
				font-size: 1.1rem;
				line-height: 1.9rem;
			}

			svg {
				max-width: 100%;
				margin-top: 1rem;
			}
		}

		.card-wrapper {
			display: flex;
			align-items: center;
			justify-content: end;
			width: 55%;

			.row-2 {
				margin-left: 2.5rem;

				.card:first-child {
					/* margin-bottom: 2.5rem; */
				}
			}
		}

		@media screen and (max-width: ${constants.breakpoints.ipad}) {
			flex-direction: column;

			.content-wrapper {
				max-width: 100%;
				padding: 0;
			}

			.card-wrapper {
				width: 100%;
				flex-direction: row;
				justify-content: center;

				.row-2 {
					margin-left: 15px;

					.card:first-child {
						/* margin: 1rem 0; */
					}

					.card:nth-child(2) {
						margin-top: 2rem !important;
					}
				}

				.card {
					/* margin: 1rem 0; */
					max-width: 22.5rem;
				}
			}
		}

		@media screen and (max-width: ${constants.breakpoints.mobile}) {
			flex-direction: column;

			.section-label {
				letter-spacing: 3px;
				margin-bottom: 1rem;
			}

			.content-wrapper {
				max-width: 100%;
				padding: 0;

				.heading {
					font-size: 35px;
					line-height: 45px;
					font-weight: 700;
				}

				.sub-heading {
					font-size: 1rem;
					font-weight: 600;
				}

				.paragraph {
					font-size: 14px;
					margin-bottom: 5rem;
					line-height: 1.5rem;
					margin-top: 2rem;
				}

				svg {
					margin-top: -10.5rem;
					margin-bottom: -5rem;
				}
			}

			.card-wrapper {
				width: 100%;
				flex-direction: column;

				.row-2 {
					margin-left: 0;

					.card:first-child {
						/* margin: 1rem 0; */
					}

					.card:nth-child(2) {
						margin-top: 2rem !important;
					}
				}

				.card {
					/* margin: 1rem 0; */
					max-width: 22.5rem;
				}
			}
		}
	}
`;

export const CardWrapper = styled.div`
	background: url("/images/background.jpg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 3px;
	border-radius: 8px;
`;

export const CardLayout = styled.div`
	border-radius: 8px;
	/* background: linear-gradient(
		to right bottom,
		rgba(250, 250, 250, 0.2),
		rgba(250, 250, 250, 0.4)
	); */
	background: ${constants.theme.background};

	max-width: 20rem;
	padding: 1.5rem 1rem;

	.heading {
		font-size: 1.75rem;
		font-weight: 600;
		margin: 1.25rem 0 1.5rem 0;
	}

	.paragraph {
		line-height: 2rem;
	}

	img {
		width: 3rem;
	}
`;
