export const socialsData = [
	{
		imageURL: "/images/footer/social/telegram.svg",
		link: "https://t.me/thoughtzAi",
		name: "Telegram",
	},
	{
		imageURL: "/images/footer/social/twitter.svg",
		link: "https://twitter.com/ThoughtzAi",
		name: "Twitter",
	},
	{
		imageURL: "/images/footer/social/linkedin.svg",
		link: "https://www.linkedin.com/company/thoughtz",
		name: "Linkedin",
	},
	{
		imageURL: "/images/footer/social/instagram.svg",
		link: "https://www.instagram.com/thoughtz.ai/",
		name: "Instagram",
	},
	{
		imageURL: "/images/footer/social/medium.svg",
		link: "https://thoughtz.medium.com/",
		name: "Medium",
	},
	{
		imageURL: "/images/footer/social/reddit.svg",
		link: "https://www.reddit.com/r/thoughtzAi/",
		name: "Reddit",
	},
	{
		imageURL: "/images/footer/social/facebook.svg",
		link: "https://www.facebook.com/ThoughtzAi",
		name: "Facebook",
	},
	{
		imageURL: "/images/footer/social/discord.svg",
		link: "https://discord.gg/vQVKVEZzNm",
		name: "Discord",
	},
];

export const items = [
	{
		text: "Think",
		emoji: "/images/footer/marquee/think.svg",
		gradientColors: ["00ff87", "60efff"],
	},
	{
		text: "Connect",
		emoji: "/images/footer/marquee/connect.svg",
		gradientColors: ["0061ff", "60efff"],
	},
	{
		text: "Collaborate",
		emoji: "/images/footer/marquee/collab.svg",
		gradientColors: ["ff1b6b", "45caff"],
	},
	{
		text: "Share",
		emoji: "/images/footer/marquee/share.svg",
		gradientColors: ["40c9ff", "e81cff"],
	},
	{
		text: "Discuss",
		emoji: "/images/footer/marquee/discuss.svg",
		gradientColors: ["ff930f", "fff95b"],
	},
	{
		text: "Exchange",
		emoji: "/images/footer/marquee/exchange.svg",
		gradientColors: ["00ff87", "60efff"],
	},
	{
		text: "Learn",
		emoji: "/images/footer/marquee/learn.svg",
		gradientColors: ["0061ff", "60efff"],
	},
];

export const footerLinks = [
	{
		label: "Home",
		link: "/",
	},
	{
		label: "How it works",
		link: "/#concept",
	},
	{
		label: "Features",
		link: "/#features",
	},
	{
		label: "About Us",
		link: "/#about",
	},
	{
		label: "Careers",
		link: "/career",
	},
	{
		label: "FAQs",
		link: "/#faq",
	},
];

export const pageLinks = [
	{
		label: "Investor Deck",
		link: "/ThoughtzPitchDeck.pdf",
	},
	{
		label: "Tokenomics",
		link: "/Tokenomics.pdf",
	},
	// {
	// 	label: "Token Sale FAQs",
	// 	link: "/ico#faq",
	// },
];
