import styled from "styled-components";
import { constants } from "../../../constants/constants";
import * as jquery from "jquery";

var isIphone = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

export const HeaderLayout = styled.div`
	height: 150vh;
	width: 100vw;
	max-width: 1400px;
	margin: 0 auto;
	margin-bottom: 2rem;

	.header-parent-wrapper {
		width: 100%;
		height: 110vh;
		display: flex;
		align-items: center;
		justify-content: center;
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		padding: 0 1rem;

		.text-container {
			&:first-child {
				margin-top: 3rem;
			}

			&:not(:first-child) {
				margin-top: -5rem;
			}
		}

		@media (max-width: ${constants.breakpoints.mobile}) {
			min-height: unset;
			padding: 0px 0 150px 0;
			margin-bottom: -40vh;

			.text-container {
				margin: -18px 0;
			}
		}
	}
`;

export const TextWithBackgroundImage = styled.p`
	background: url("/images/background.jpg");
	background-image: transparent;
	background-position: ${({ backgroundPosition }) => backgroundPosition};
	background-position: center;
	-webkit-text-fill-color: transparent;
	-webkit-background-clip: text;
	background-clip: text;
	font-size: 16rem;
	font-weight: 900;
	background-repeat: no-repeat;
	width: 100%;
	text-align: ${({ textAlign }) => textAlign};
	padding: 0 1rem;
	overflow-x: hidden;
	line-height: 20rem;
	overflow-y: hidden;

	transform: translateX(
		${function ({ scrollPercent, textAlign }) {
			if (scrollPercent > 100) return 0;
			if (isIphone) {
				// alert(jquery(window).width());
				return textAlign === "start"
					? -jquery(window).width() / 10 +
							((jquery(window).width() / 10) * scrollPercent) /
								100
					: jquery(window).width() / 10 -
							((jquery(window).width() / 10) * scrollPercent) /
								100;
			} else
				return textAlign === "start"
					? -window.innerWidth / 10 +
							((window.innerWidth / 10) * scrollPercent) / 100
					: window.innerWidth / 10 -
							((window.innerWidth / 10) * scrollPercent) / 100;
		}}px
	);

	background-size: 300%;

	animation: ${({ textAlign }) =>
				textAlign === "start" ? "slideFromLeft" : "slideFromRight"}
			0.7s ease-out,
		animatedGradient 8s ease infinite alternate-reverse;

	@media (max-width: ${constants.breakpoints.ipad}) {
		font-size: 8rem;
		line-height: 15rem;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		font-size: 4rem;
		line-height: 9rem;
		width: 100%;
		overflow-x: hidden;

		transform: translateX(
			${function ({ scrollPercent, textAlign }) {
				if (scrollPercent > 100) return 0;
				if (isIphone)
					return textAlign === "start"
						? -window.screen.width / 10 +
								((window.screen.width / 10) * scrollPercent) /
									100
						: window.screen.width / 10 -
								((window.screen.width / 10) * scrollPercent) /
									100;
				else
					return textAlign === "start"
						? -window.innerWidth / 10 +
								((window.innerWidth / 10) * scrollPercent) / 100
						: window.innerWidth / 10 -
								((window.innerWidth / 10) * scrollPercent) /
									100;
			}}px
		);
	}

	@keyframes slideFromLeft {
		0% {
			transform: translateX(-20rem);
		}

		100% {
			transform: translateX(-10rem);
		}
	}

	@keyframes slideFromRight {
		0% {
			transform: translateX(20rem);
		}

		100% {
			transform: translateX(10rem);
		}
	}

	@keyframes animatedGradient {
		from {
			background-position: 0%;
		}

		to {
			background-position: 100%;
		}
	}
`;
