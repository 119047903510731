import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper";

import Card from "react-bootstrap/Card";

import { BlogLayout } from "./styles";
import { BlogsData } from "./data";

const Blog = () => {
	return (
		<BlogLayout>
			<div className="textWrapper">
				<p data-aos="fade-up" className="gradientHeading">
					Blog
				</p>
			</div>
			<div data-aos="fade-up">
				<Swiper
					slidesPerView={1}
					centeredSlides={false}
					slidesPerGroupSkip={1}
					grabCursor={false}
					keyboard={{
						enabled: true,
					}}
					breakpoints={{
						769: {
							slidesPerView: 3,
							slidesPerGroup: 1,
							spaceBetween: 30,
						},
					}}
					navigation={{
						nextEl: ".next",
						prevEl: ".prev",
					}}
					modules={[Keyboard, Scrollbar, Navigation, Pagination]}
					className="mySwiper"
				>
					{BlogsData.map((each, index) => {
						return (
							<div key={index}>
								<SwiperSlide>
									<a href={each.link} target="_blank">
										<Card bsPrefix="card-sec">
											<Card.Img
												variant="top"
												src={each.img}
											/>
											<Card.Body bsPrefix="card-bod">
												<Card.Subtitle bsPrefix="card-sub">
													{each.subtitle}
												</Card.Subtitle>
												<Card.Title bsPrefix="card-title">
													{each.title}
												</Card.Title>
												<Card.Text bsPrefix="card-des">
													{each.description}
												</Card.Text>{" "}
												<span>Read more ...</span>
											</Card.Body>
										</Card>
									</a>
								</SwiperSlide>
							</div>
						);
					})}
				</Swiper>
			</div>

			<div className="bottomSection" data-aos="fade-up">
				<div className="browse">
					<a href="https://thoughtz.medium.com/" target="_blank">
						<img
							src="/images/footer/social/medium.svg"
							alt="medium-logo"
						/>{" "}
						<p>Explore All Articles</p>{" "}
						<img
							src="/images/Blog/RightArrow.svg"
							alt="right-arrow"
						/>
					</a>
				</div>
				<div className="controls">
					<span className="prev">
						<img
							src="/images/Blog/LeftArrow.svg"
							alt="left-arrow"
						/>
					</span>
					<span className="next">
						<img
							src="/images/Blog/RightArrow.svg"
							alt="right-arrow"
						/>
					</span>
				</div>
			</div>
		</BlogLayout>
	);
};

export default Blog;
