import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const CommunityLayout= styled.div`

    background: ${ constants.theme.background };
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 13rem 1rem 1.5rem;

    h1 {
        font-size: 2.75rem;
        font-weight: 600;
        margin-bottom: 2rem;
    }

    .description {
        font-size: 18px;
        margin-bottom: 3rem;

        span {
            font-weight: 600;
            margin: 0 3px;
        }

        .colored {
            color: #d02896;
        }
    }

    @media (max-width: ${ constants.breakpoints.mobile }) {

        padding: 10rem 1rem 0;

        .description {
            font-size: 1rem;
            text-align: center;
        }
    }

    .donation-box {
        display: flex;
        background: rgb(15,18,22);
        padding: 2rem;
        border-radius: 20px;
        align-items: center;

        @media (max-width: ${ constants.breakpoints.mobile }) {
            flex-direction: column;
            padding: 2rem 1rem;
            max-width: 100%;

            p {
                margin: 0 0 1.5rem 0;
                overflow: hidden;
                max-width: calc(100% - 2rem);
            }
        }

        p {
            margin-right: 2rem;
        }

        button {
            border: 1px solid #d02896;
            padding: 1rem 1.5rem;
            border-radius: 35px;
            background: transparent;
            position: relative;
            z-index: 2;
            display: flex;
            align-items: center;

            svg {
                margin-right: 12px;
                z-index: 2;
            }

            span {
                z-index: 2;
            }

            .layer {
                width: 50px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 35px;
                z-index: 1;
                transition: all 0.5s ease-out;
            }

            &:hover {
                .layer {
                    width: 100%;
                    background: #d02896;
                }
            }
        }
    }
`