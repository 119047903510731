import React, { useEffect } from "react";
import { ICOLayout } from "./styles";
import { Footer } from "../../components/home/footer/footer";
import { Navbar } from "../../components/global/navbar/navbar";
import { useAos } from "../../hooks/useAos";

//Components
import Header from "../../components/icoComponent/header/Header";
import Pricing from "../../components/icoComponent/Pricing/Pricing";
import PieChart from "../../components/icoComponent/chart/PieChart";
import IcoFaq from "../../components/icoComponent/faq/IcoFaq";
import TokenAllocation from "../../components/icoComponent/TokenAllocation/TokenAllocation";

//chakraUI
import { Button } from "@chakra-ui/react";
import { Description } from "../../components/icoComponent/description/description";
import Roadmap from "../../components/icoComponent/Roadmap/Roadmap";

const Ico = () => {
	const data = [
		{
			id: 0,
			header: "Q1, 2021",
			content: [
				"Initial Brainstorming",
				"Market and Competitor research",
				"User Feedback analysis",
				"Built the Foundation and Structure of the idea ",
			],
		},
		{
			id: 1,
			header: "Q2, 2021",
			content: [
				"Finalized the features of the product",
				"Initialized the Designs",
				"Finalized the Prototype",
			],
		},
		{
			id: 2,
			header: "Q3, 2021",
			content: [
				"Building a team",
				"App Development",
				"Developed MVP",
				"Obtained feedback from initial test users",
			],
		},
		{
			id: 3,
			header: "Q4, 2021",
			content: [
				"Development of Alpha version of the App",
				"Drafted the White paper",
				"Website and Social Media channels release",
				"Dashboard platform for token holders",
				"Thoughtz Wallet",
			],
		},
		{
			id: 4,
			header: "Q1, 2022",
			content: [
				"Private Sale and Public token sale ",
				"Marketing",
				"Creating Smart Contracts in BSC",
				"Token Distribution",
				"Reference bonus",
				"Community Airdrop",
			],
		},
		{
			id: 5,
			header: "Q2, 2022",
			content: [
				"Release Thoughtz App beta version ",
				"Create Cross Chain contracts in Ethereum and Polygon Matic",
				"Exclusive Giveaways/ Rewards ",
				"Rewards and Incentives to the the users",
				"Acquisition of Users",
				"Listing in DEX (Pancakeswap)",
			],
		},
		{
			id: 6,
			header: "Q3, 2022",
			content: [
				"Onboard Experts and Influencers",
				"Mint Thought NFTs",
				"Implement Ads and Premium features",
				"Implement Groups",
				"Exchange listing",
				"Build Decentralized app",
				"Thoughts NFT marketplace",
			],
		},
		{
			id: 7,
			header: "Q4, 2022",
			content: [
				"Expert marketplace",
				"Implement Polls and get feedback on your thoughts",
				"Brands offering products for discounts",
				"Data Analytics and APIs",
			],
		},
	];

	useEffect(() => {
		document.title = "ICO | Thoughtz.ai";
	}, []);

	useAos(1000);

	return (
		<>
			<Navbar />
			<ICOLayout data-aos="fade-up" data-aos-duration="1500">
				<h1 className="primaryColor heading-1">Thoughtz Token Sale</h1>
				<h1 className="heading text-center">
					Platform For Every Crypto <br />
					Enthusiast
				</h1>
				<hr className="dividerICO" />
				<br />
				<p>
					THOUGHTZ has gently constructed a high-potential ICO
					platform to gingerly assist users in every <br /> manner
					imaginable while keeping things clear to the core as well as
					implementing a simple user interface.
				</p>
				{/* <div className=" text-center"></div> */}
				<div className="mt-3 mb-2">
					<Button className="paperBtn">
						<a href="/whitepaper.pdf" target="_blank">
							WHITE PAPER
						</a>
					</Button>
					<btn className="tokenStyle btn">
						<a href="#tokens">TOKEN DISTRIBUTION</a>
					</btn>
				</div>
				{/* <Header /> */}
			</ICOLayout>

			<Description />
			<Roadmap data={data} />
			<span id="tokens"></span>
			<br />
			<br />

			<p className="grad-head mb-5" data-aos="fade-up">
				Token Distribution
			</p>
			<PieChart />
			<p className="grad-head mb-5" data-aos="fade-up">
				Token Allocation
			</p>
			<TokenAllocation />
			<Pricing />
			<IcoFaq />
			<Footer />
		</>
	);
};

export default Ico;
