import { data } from "./data";
import { AboutLayout, CardLayout, CardWrapper } from "./styles";
import { ReactComponent as Map } from "../../../svg/map.svg";
import { useAos } from "../../../hooks/useAos";

const Card = ({ item }) => {
	useAos(1000);

	return (
		<>
			<CardWrapper data-aos="fade-up" className="mb-5 mt-4">
				<CardLayout className="card">
					<img src={item.icon} alt={`${item.title}-logo`} />
					<div className="heading">{item.title}</div>
					{typeof item.description === "string" ? (
						<p className="paragraph">{item.description}</p>
					) : (
						item.description.map((paragraph, i) => (
							<p key={i} style={{ lineHeight: "1.8rem" }}>
								{paragraph}
							</p>
						))
					)}
				</CardLayout>
			</CardWrapper>
		</>
	);
};

export default function About() {
	return (
		<>
			<AboutLayout id="about" data-aos="fade-up">
				<div
					className="section-label"
					data-aos="fade-up"
					style={{ marginTop: "8rem" }}
				>
					ABOUT US
				</div>
				<div className="wrapper">
					<div className="content-wrapper">
						{["Our Mission, Vision", "& Values"].map(
							(item, index) => (
								<div
									data-aos="fade-up"
									className="heading"
									key={index}
								>
									{item}
								</div>
							)
						)}

						<p data-aos="fade-up" className="sub-heading">
							{subHeading}
						</p>

						<p data-aos="fade-up" className="paragraph">
							{paragraph}
						</p>

						<Map />
					</div>

					<div className="card-wrapper">
						<div>
							<Card item={data[0]} />
						</div>
						<div className="row-2">
							<Card item={data[1]} />
							<Card item={data[2]} />
						</div>
					</div>
				</div>
			</AboutLayout>
		</>
	);
}

const paragraph =
		"We love collaboration, we value teams, and enjoy open communication and transparency. We passionately innovate to create experiences that delight users.",
	subHeading =
		"We help people get closer and connected and make things happen";
