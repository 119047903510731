import styled from "styled-components";
import { constants } from "../../constants/constants";

export const ICOLayout = styled.div`
	${'' /* min-height: 150vh; */}
	min-height: 100vh;
	margin-top: 5rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem;

	.heading-1{
		margin: 1rem 0;
        font-weight: 600;
		font-size: 5rem;
		background: linear-gradient(
			to right,
			rgb(208, 40, 155),
			rgb(249, 104, 119)
		);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
    }

	h1{
		margin: 1rem 0;
        font-weight: 600;
		font-size: 3rem;
	}

	p{
		text-align: center;
	}

	.paperBtn {
		padding: 1rem 2rem;
		background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
		color: white;
		font-size: 0.9rem;
		&:hover {
			background: linear-gradient(-225deg, #0055ff 0%, #f22bb2 100%);
		}
	}

	.sub-heading-1 {
		font-size: 2rem !important;
		font-weight: 500;
	}

	.grad-head {
		margin-top: 1rem;
		font-weight: 600;
		font-size: 7rem;
		background: linear-gradient(
			to right,
			rgb(208, 40, 155),
			rgb(249, 104, 119)
		);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

	.dividerICO {
		/* padding: 5px; */
		height: 4px;
		background-color: rgba(242, 43, 179, 1);
		border-radius: 10px;
		width: 120px;
		margin-top: 1rem;
		opacity: 1;
	}

	.tokenStyle {
		padding: 1rem 1.5rem;
		color: white;
		&:hover {
			color: rgb(242, 43, 179);
		}
	}

	.primaryColor {
		color: rgba(242, 43, 179, 1);
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		text-align: center;

		.heading-1{
			font-size: 3rem;
		}

		h1{
			font-size: 1.5rem;
		}

		.grad-head {
			margin-top: 1rem;
			font-weight: 600;
			font-size: 3rem;
			background: linear-gradient(
				to right,
				rgb(208, 40, 155),
				rgb(249, 104, 119)
			);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}


	}
`;
