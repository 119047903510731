import profile_1 from "../../../images/profile_1.jpg";
import profile_2 from "../../../images/profile_2.jpg";
import profile_3 from "../../../images/profile_3.jpg";
import profile_4 from "../../../images/profile_4.jpg";
import profile_5 from "../../../images/profile_5.jpg";
import profile_6 from "../../../images/profile_6.jpg";
import profile_7 from "../../../images/profile_7.jpg";
import profile_8 from "../../../images/profile_8.jpg";
import profile_9 from "../../../images/profile_9.jpg";
import profile_10 from "../../../images/profile_10.jpg";
import profile_11 from "../../../images/profile_11.jpg";
import profile_12 from "../../../images/profile_12.jpg";
import profile_13 from "../../../images/advisor1.jpg";
import profile_14 from "../../../images/advisor2.png";
import profile_15 from "../../../images/profile_15.jpg";

export const teamMembers = [
	{
		url: profile_7,
		name: "Siva Dantuluri",
		position: "Founder and CEO",
		quote: "You never fail until you stop trying",
		description:
			"Perfectionniste dans sa vie comme dans son boulot, Olivier aime produire du code optimisé et performant.",
		skills: ["data", "tracking"],
		linkedinURL: "https://www.linkedin.com/in/sivadantuluri17",
	},

	{
		url: profile_4,
		name: "Bharath Kanakamedala",
		position: "Chief Architect",
		quote: "You never fail until you stop trying",
		description: "Perfectionniste dans sa vie comme dans son boulot.",
		skills: ["data", "tracking"],
		linkedinURL:
			"https://www.linkedin.com/in/kanakamedala-bharath-kumar-21ba5975/",
	},
	{
		url: profile_9,
		name: "Sudheer Tammana",
		position: "AI/ML/NLP Architect and Expert",
		quote: "You never fail until you stop trying",
		description:
			"Perfectionniste dans sa vie comme dans son boulot, Olivier aime produire du code optimisé et performant.",
		skills: ["data", "tracking"],
		linkedinURL: "https://www.linkedin.com/in/sudheer-tammana-295a6237/",
	},
];

export const teamMembers2 = [
	{
		url: profile_6,
		name: "Mugdha Jain",
		position: "Research Specialist",
		quote: "You never fail until you stop trying",
		description:
			"Perfectionniste dans sa vie comme dans son boulot, Olivier aime produire du code optimisé et performant.",
		skills: ["data", "tracking"],
		linkedinURL: "https://www.linkedin.com/in/mugdha-jain-5911a5180",
	},
	{
		url: profile_10,
		name: "Rajat Gupta",
		position: "Full Stack Developer",
		quote: "You never fail until you stop trying",
		description:
			"Perfectionniste dans sa vie comme dans son boulot, Olivier aime produire du code optimisé et performant.",
		skills: ["data", "tracking"],
		linkedinURL: "https://www.linkedin.com/in/rajatgupta24/",
	},
	{
		url: profile_11,
		name: "Suraj Sisodia",
		position: "Flutter Mobile Developer",
		quote: "You never fail until you stop trying",
		description:
			"Perfectionniste dans sa vie comme dans son boulot, Olivier aime produire du code optimisé et performant.",
		skills: ["data", "tracking"],
		linkedinURL: "https://www.linkedin.com/in/ss26/",
	},
];

export const teamMembers3 = [
	{
		url: profile_15,
		name: "Nikhil Gupta",
		position: "Mobile App Developer",
		quote: "You never fail until you stop trying",
		description:
			"Perfectionniste dans sa vie comme dans son boulot, Olivier aime produire du code optimisé et performant.",
		skills: ["data", "tracking"],
		linkedinURL: "https://www.linkedin.com/in/nikhil-gupta-56b607194/",
	},
	{},
	{},
];

export const advisors = [
	{
		url: profile_13,
		name: "Nacha Chondamrongkul",
		position: "Technical Advisor",
		quote: "You never fail until you stop trying",
		description:
			"Perfectionniste dans sa vie comme dans son boulot, Olivier aime produire du code optimisé et performant.",
		skills: ["data", "tracking"],
		linkedinURL: "https://www.linkedin.com/in/cnacha/",
	},
	{
		url: profile_14,
		name: "Vinodh Venkatesan",
		position: "Data and AI Advisor",
		quote: "You never fail until you stop trying",
		description:
			"Perfectionniste dans sa vie comme dans son boulot, Olivier aime produire du code optimisé et performant.",
		skills: ["data", "tracking"],
		linkedinURL: "https://www.linkedin.com/in/vinodhvenkatesan/",
	},
	{},
];
