import styled from "styled-components";
import { constants } from "../../../constants/constants";


export const PressLayout = styled.section`
.textWrapper {
    padding: 5rem 0 3rem 0;

    .gradientHeading {
        font-weight: bold;
        font-size: 58px;
        background: linear-gradient(to right, rgb(201,40,152), rgb(226,39,144));
        width: fit-content;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-background-clip: text;
        line-height: 105px;
        line-height: 100px;
        width: max-content;
    }
}



@media (max-width: ${ constants.breakpoints.mobile }) {
    .textWrapper {
        padding-bottom: 2rem;

        .gradientHeading {
            font-size: 35px;
            line-height: 45px;
        }
    }
  }

`
