import { Fragment, useState, useEffect } from "react";
import { Flex } from "@chakra-ui/react";
import { BiMenuAltLeft } from "react-icons/bi";
import { NavbarLayout } from "./styles";
import { IoIosClose } from "react-icons/io";
import { useMediaQuery } from "react-responsive";
import { constants } from "../../../constants/constants";
import { Link, useLocation } from "react-router-dom";
import { navLinks, topNav } from "./data";

export const Navbar = () => {
	const { pathname, hash } = useLocation();
	const [showMobileNav, setShowMobileNav] = useState(false);
	const [isVisible, setIsVisible] = useState(true);

	const pathLink = pathname + hash;

	const isMobile = useMediaQuery({
		query: `(max-width: ${constants.breakpoints.ipad})`,
	});
	const toggleShowMobileNav = () =>
		setShowMobileNav((prevState) => !prevState);

	useEffect(() => {
		window.addEventListener("scroll", listenToScroll);
		return () => window.removeEventListener("scroll", listenToScroll);
	}, []);

	const listenToScroll = () => {
		let heightToHideFrom = 400;
		const winScroll =
			document.body.scrollTop || document.documentElement.scrollTop;

		if (winScroll > heightToHideFrom) {
			isVisible && setIsVisible(false);
		} else {
			setIsVisible(true);
		}
	};

	return (
		<Fragment>
			<NavbarLayout>
				{isVisible && (
					<div className="topNav">
						<p className="topNavLink">
							<a href="/Tokenomics.pdf" target="_blank">
								Tokenomics
							</a>
						</p>
						<p className="topNavLink me-4">
							<a href="/whitepaper.pdf" target="_blank">
								Whitepaper
							</a>
						</p>

						{topNav.map((nav, i) => (
							<p className="topNavLink">
								<a href={nav.link} target="_blank">
									<img
										className="ico"
										src={nav.imageURL}
										alt={nav.name}
									/>
								</a>
							</p>
						))}
					</div>
				)}

				<Flex
					justifyContent="space-between"
					alignItems="center"
					className="nav-bar"
				>
					<a href="/">
						<Flex alignItems="center" className="brand-logo">
							{/* <Logo /> */}
							<img
								className="logo-item"
								src="/images/logos/img1.jpg"
								alt="thoughtz-logo"
							/>
							<p>Thoughtz</p>
						</Flex>
					</a>

					<Flex alignItems="center" className="nav-options">
						{!isMobile && (
							<Fragment>
								{navLinks.map((navLink, index) => (
									<p className="na-link" key={index}>
										<a href={navLink.route}>
											{navLink.navLink}
											{navLink.navLink ===
												"Private sale" && (
												<span className="live">
													Live
												</span>
											)}
										</a>
										{pathLink === navLink.route && (
											<div className="underline"></div>
										)}
									</p>
								))}
								<a
									href="/ThoughtzPitchDeck.pdf"
									target="_blank"
								>
									<button>Pitch Deck</button>
								</a>
							</Fragment>
						)}

						{!showMobileNav ? (
							<BiMenuAltLeft
								size={25}
								onClick={toggleShowMobileNav}
							/>
						) : (
							<IoIosClose
								size={28}
								onClick={toggleShowMobileNav}
							/>
						)}
					</Flex>
				</Flex>

				{isMobile && showMobileNav && (
					<div className="mobile-menu">
						{navLinks.map((navLink, index) => (
							<p className="na-link" key={index}>
								<a
									href={navLink.route}
									onClick={toggleShowMobileNav}
								>
									{navLink.navLink}
								</a>
								{pathLink === navLink.route && (
									<div className="underline"></div>
								)}
							</p>
						))}
						<a href="/ThoughtzPitchDeck.pdf" target="_blank">
							<button>Pitch Deck</button>
						</a>
					</div>
				)}
			</NavbarLayout>
		</Fragment>
	);
};
