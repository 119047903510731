import React, { Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaAngleDoubleRight } from "react-icons/fa";
import { animateScroll as scroll } from "react-scroll";

import { CareerLayout } from "./styled";
import { career } from "./data";

//Components
import { Navbar } from "../../components/global/navbar/navbar";
import { Footer } from "../../components/home/footer/footer";
import { useAos } from "../../hooks/useAos";

const { head1, head2, head3, boxes } = career;

export default function Career() {
	useAos(1000);
	const page1 = useRef();

	useEffect(() => {
		document.title = "Careers | Thoughtz.ai";
	}, []);

	const scrollDown = (e) => {
		e.preventDefault();
		scroll.scrollMore(800, {
			duration: 100,
			delay: 0,
			smooth: "easeInOutQuart",
		});
	};

	return (
		<Fragment>
			<Navbar />

			<CareerLayout>
				<div className="carrer-container d-flex flex-column justify-content-center container">
					<div
						ref={page1}
						className="page1 heading-grad"
						data-aos="fade-up"
						data-aos-duration="1000"
					>
						<h2>{head1}</h2>
					</div>
					<div
						className="text-p"
						data-aos="fade-up"
						data-aos-duration="1000"
					>
						<p className="page2">
							We challenge you, you challenge us.
						</p>
						<p className="page2">
							We learn, grow and succeed as one.
						</p>
						<p className="page2">Be a part of what comes next.</p>
					</div>
					<div onClick={scrollDown}>
						<span className="arrow d-sm-block d-none"></span>
					</div>
				</div>
				<div
					data-aos="fade-up"
					data-aos-duration="1000"
					className="carrer-container_content"
				>
					<h2 className="text-center">{head2}</h2>
					<h2 className="text-center mb-3">{head3}</h2>
					<div className="d-flex  justify-content-center">
						<div className="separation" />
					</div>
					<div className="carrer-container_boxes">
						{boxes.map(({ position, type, link }, index) => (
							<div
								key={index}
								data-aos="fade-up"
								data-aos-duration="1600"
							>
								<h2>{position}</h2>
								<span>{type}</span>

								<Link
									style={{
										background: "#000",
										margin: "2px",
									}}
									to={link}
								>
									<FaAngleDoubleRight color="#fff" />
								</Link>
							</div>
						))}
					</div>
				</div>
			</CareerLayout>
			<Footer />
		</Fragment>
	);
}
