import React, { useRef, useState, useEffect } from "react";
import { useAos } from "../../hooks/useAos";
import { Footer } from "../../components/home/footer/footer";
import { Navbar } from "../../components/global/navbar/navbar";
import { PrivateSaleCont, RegisterBtn } from "./styles.js";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

import { JobsLayout } from "../jobs/styles";
import { inputFields } from "./data";

const PrivateSale = () => {
	const formRef = useRef();

	useEffect(() => {
		document.title = "Private Sale | Thoughtz.ai";
	}, []);

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		country: "",
		message: "",
	});
	const [loading, setLoading] = useState();

	const changeHandler = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const emailValidation = (email) => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (!email || regex.test(email) === false) {
			toast.error("Email invalid");
			setLoading(false);
			return false;
		}
		return true;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const { first_name, last_name, email, country, message } = formData;
		if ((!first_name, !last_name, !email, !country)) {
			toast.error("Please fill all the required fields");
			setLoading(false);
		} else {
			let emailRes = emailValidation(email);

			if (emailRes === false) return;

			let res = await axios.post(
				"https://us-central1-thoughtz-app.cloudfunctions.net/privateSale",
				{
					first_name,
					last_name,
					email,
					country,
					message,
				}
			);

			if (res.status === 201) toast.success("Successfully Registered");
			else toast.error("There was an error");

			setFormData({
				first_name: "",
				last_name: "",
				email: "",
				country: "",
				message: "",
			});
		}
		setLoading(false);
	};

	useAos(1000);
	return (
		<>
			<Navbar />
			<PrivateSaleCont data-aos="fade-up" data-aos-duration="1500">
				<h1>Thoughtz Private Sale</h1>
				<div className="mb-3">
					<p>
						THZ token price on Private Sale :{" "}
						<span
							className="fw-bold"
							style={{ color: "rgb(245,98,122)" }}
						>
							0.03 USDT
						</span>{" "}
						(Minimum purchase - $1000)
					</p>
					<p>
						(10% vesting at token launch, followed by 4 equal
						quarterly unlocks for the next 12 months)
					</p>
				</div>

				<p className="px-3">
					To participate in a private sale, please register below.
				</p>
				<RegisterBtn>
					<a href="#1">Register Now!</a>
				</RegisterBtn>
			</PrivateSaleCont>

			<div style={{ height: "11vh" }} id="1"></div>

			<PrivateSaleCont
				style={{ height: "auto" }}
				data-aos="fade-up"
				data-aos-duration="1500"
			>
				<h1 style={{ margin: 0 }} className="heading">
					Registration Details
				</h1>
			</PrivateSaleCont>

			<JobsLayout>
				<div
					className="jobs-main-container"
					style={{ width: "100%", padding: "1rem" }}
				>
					<div className="formContainer">
						<div ref={formRef} className="job-contact-form">
							<div className="title">
								<p
									style={{
										fontSize: "35px",
										marginBottom: "10px",
									}}
								>
									Register
								</p>
								<p>Thoughtz Private Sale</p>
								<div className="my-md-5 my-3">OR</div>
								<p
									style={{
										fontSize: "35px",
										marginBottom: "10px",
									}}
								>
									Send email to
								</p>
								<p>
									<a
										href="mailto:privatesale@thoughtz.ai"
										target="_blank"
									>
										privatesale@thoughtz.ai
									</a>
								</p>
							</div>
							<form className="form">
								{inputFields.map(
									(
										{ type, name, required, label },
										index
									) => (
										<div
											className="input-fields"
											key={index}
										>
											<label>
												{label}
												{required ? (
													<span
														className="small text-muted"
														style={spanStyle}
													>
														*Required
													</span>
												) : (
													<span
														className="small text-muted"
														style={spanStyle}
													>
														(optional)
													</span>
												)}
											</label>

											{name === "message" ? (
												<textarea
													name={name}
													cols="20"
													rows="3"
													value={formData[name]}
													required={required}
													onChange={(e) =>
														changeHandler(e)
													}
													autoComplete={"off"}
													id={
														type === "file"
															? "resume"
															: null
													}
												/>
											) : (
												<input
													type={type}
													name={name}
													value={formData[name]}
													required={required}
													onChange={(e) =>
														changeHandler(e)
													}
													autoComplete={"off"}
													id={
														type === "file"
															? "resume"
															: null
													}
												/>
											)}
											{type === "file" && (
												<label
													className="resume-lable-input"
													htmlFor="resume"
												>
													Upload
												</label>
											)}
											{type === "file" && (
												<span
													style={{
														fontSize: "13px",
													}}
												>
													{formData.resume}
												</span>
											)}
										</div>
									)
								)}
								<button
									type="submit"
									onClick={handleSubmit}
									className="submitButton"
								>
									{loading && (
										<span
											class="spinner-border spinner-border-sm me-2"
											role="status"
											aria-hidden="true"
										></span>
									)}
									Submit
								</button>
							</form>
						</div>
					</div>
				</div>
			</JobsLayout>
			<Toaster />
			<Footer />
		</>
	);
};

export default PrivateSale;

const spanStyle = {
	color: "var(--gray-color)",
	marginLeft: "10px",
};
