export const features = [
	{
		svg: "/images/features/Thoughts.png",
		heading: "Enter Thoughts",
		description:
			"Let your thoughts come to life. Simply enter them into the app and share it with others who might be thinking about the same thing.",
	},
	{
		svg: "/images/features/Swipe.png",
		heading: "Swipe Right",
		description:
			"You'll meet people who think like you. As we say, two brains are better than one. Just swipe right if you are interested. If the other person is interested too, it's a match!",
	},
];

export const features_2 = [
	{
		svg: "/images/features/connection.png",
		heading: "Make a Connection",
		description:
			"Got a match ? Once you’ve found a match who shares your interests, the possibilities are endless. We connect you with like-minded individuals in real-time, so you waste no time getting your ideas out into the world.",
	},
	{
		svg: "/images/features/Engage.png",
		heading: "Engage",
		description:
			"Enjoy the freshness of spontaneous discussions and the potential of an infinite number of social interactions. Put ideas into action and make things happen.",
	},
];

export const deviceImageURL = "/images/flatiPhone.png";
