import { Flex } from "@chakra-ui/react";
import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const OverviewWrapper = styled.div`
	height: 150vh;

	.heading {
		background: linear-gradient(
			to right,
			rgb(208, 40, 155),
			rgb(249, 104, 119)
		);
		background-size: cover;
		background-position: center;
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		font-size: 60px;
		font-weight: 600;
		line-height: 70px;
		margin-top: 3.5rem;
		@media (max-width: ${constants.breakpoints.ipad}) {
			font-size: 35px;
			line-height: 45px;
			font-weight: 700;
		}
	}

	@media (max-width: ${constants.breakpoints.ipad}) {
		height: fit-content;
		margin: 3.5rem 0 7.5rem 0;
	}
`;

export const OverviewLayout = styled(Flex)`
	width: 100vw;
	max-width: 1400px;
	min-height: 100vh;
	padding: 1rem;
	flex-shrink: 0;
	justify-content: space-evenly;
	align-items: center;
	position: sticky;
	position: -webkit-sticky;
	top: 0px;

	

	.image {
		max-width: 20vw;
		/* max-width: 600px; */
		min-height: 70vh; */
	}

	@media (max-width: ${constants.breakpoints.ipad}) {
		flex-direction: column;

		.image {
			display: none;
		}
	}
`;

export const StickeyCard = styled.div`
	max-width: 27rem;
	border-radius: 10px;
	padding: 1.5rem 1rem;
	background: ${function ({ showDetails, index }) {
		if (showDetails) {
			if (index === 0) return "linear-gradient(135deg, #40c9ff, #e81cff)";
			else if (index === 1)
				return "linear-gradient(135deg, #0061ff, #60efff)";
			else return "linear-gradient(135deg, #d445ba, #e7a650)";
		}
	}};

	.image1 {
		max-width: 250px;
		height: auto;
		margin: 2rem 0;
	}

	&:not(:last-child) {
		margin-bottom: 3rem;
	}

	h2 {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: 1.5rem;
	}

	p {
		color: rgb(220, 220, 220);
	}

	@media (max-width: ${constants.breakpoints.ipad}) {
		background: none;

		&:not(:last-child) {
			margin-bottom: 0rem;
		}
	}
`;

export const DivWithBackground = styled.div`
	background: url("${({ uri }) => uri}");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100vh;
	border-radius: 10px;
	margin-bottom: 3.5rem;
`;
