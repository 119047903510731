import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const Loader = styled.div`
	scroll-behavior: smooth;
	.loader {
		width: 48px;
		height: 48px;
		margin: auto;
		position: relative;
	}
	.loader:before {
		content: "";
		width: 48px;
		height: 5px;
		background: #000;
		opacity: 0.25;
		position: absolute;
		top: 60px;
		left: 0;
		border-radius: 50%;
		animation: shadow 0.5s linear infinite;
	}
	.loader:after {
		content: "";
		width: 100%;
		height: 100%;
		background: #fff;
		animation: bxSpin 0.5s linear infinite;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 4px;
	}
	@keyframes bxSpin {
		17% {
			border-bottom-right-radius: 3px;
		}
		25% {
			transform: translateY(9px) rotate(22.5deg);
		}
		50% {
			transform: translateY(18px) scale(1, 0.9) rotate(45deg);
			border-bottom-right-radius: 40px;
		}
		75% {
			transform: translateY(9px) rotate(67.5deg);
		}
		100% {
			transform: translateY(0) rotate(90deg);
		}
	}

	@keyframes shadow {
		0%,
		100% {
			transform: scale(1, 1);
		}
		50% {
			transform: scale(1.2, 1);
		}
	}
`;

export const MeetLayout = styled.div`
	width: 100%;
	height: 100vh;
	/* scroll-snap-align: start; */

	.hero {
		display: flex;
		justify-self: center;
		text-align: center;
		align-items: center;
		flex-direction: column;
		position: relative;
		min-width: 600px;
		width: 100%;
		height: 100vh;
		padding-top: 0.5rem;
	}

	.videoTag {
		opacity: 0.3;
		margin-left: -25px;
		margin-top: 30px;
		max-width: 98%;
	}

	.mob {
		display: none;
	}
	.top-section {
		display: flex;
		flex-direction: column;
		position: absolute;
		top: 53%;
		left: 50%;
		transform: translate(-50%, -50%);

		h1 {
			font-size: 7.5rem;
			font-weight: 700;
			line-height: 6.5rem;
			text-align: center;
			white-space: nowrap;
		}
		h2 {
			font-size: 1.3rem;
			font-weight: 600;
			text-align: center;
			margin-top: 1rem;
		}
	}

	.heading-grad {
		.think {
			animation: thinkcolor 9s infinite;
		}
		@keyframes thinkcolor {
			0% {
				color: white;
			}
			10% {
				color: #f34dac;
			}
			20% {
				color: #f34dac;
			}
			25% {
				color: #f34dac;
			}
			30% {
				color: #ce55b0;
			}
			40% {
				color: white;
			}
			50% {
				color: white;
			}
			60% {
				color: white;
			}
			70% {
				color: white;
			}
			80% {
				color: white;
			}
			90% {
				color: white;
			}
			100% {
				color: white;
			}
		}
		.connect {
			animation: connectcolor 9s infinite;
		}
		@keyframes connectcolor {
			0% {
				color: white;
			}
			10% {
				color: white;
			}
			20% {
				color: white;
			}
			30% {
				color: white;
			}
			40% {
				color: #f0b832;
			}
			50% {
				color: #f0b832;
			}
			60% {
				color: #b2ce37;
			}
			70% {
				color: white;
			}
			80% {
				color: white;
			}
			90% {
				color: white;
			}
			100% {
				color: white;
			}
		}
		.earn {
			animation: earncolor 9s infinite;
		}
		@keyframes earncolor {
			0% {
				color: white;
			}
			10% {
				color: white;
			}
			20% {
				color: white;
			}
			30% {
				color: white;
			}
			40% {
				color: white;
			}
			50% {
				color: white;
			}
			60% {
				color: white;
			}
			70% {
				color: #1cc2df;
			}
			80% {
				color: #1cc2df;
			}
			90% {
				color: #0580e6;
			}
			100% {
				color: white;
			}
		}
	}

	.scroll-down {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		position: absolute;
		bottom: 15px;
		span {
			transition: opacity 0.6s, transform 0.6s, -webkit-transform 0.6s,
				-moz-transform 0.6s;
			width: 55px;
			height: 55px;
			border: 1px solid rgba(250, 250, 250, 0.5);
			border-radius: 40px;
			cursor: pointer;
			transition: all linear 0.4s;
			transform: rotateZ(90deg);
			margin-top: 3.5rem;
			display: block;
			position: static;

			&:before {
				width: 45px;
				content: "";
				height: 2px;
				background: white;
				position: absolute;
				margin-top: -1px;
				right: 50%;
				top: 50%;
				transition: all 0.3s ease;
			}

			&:after {
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 5px 0 5px 5px;
				border-color: transparent transparent transparent white;
				position: absolute;
				margin-top: -5px;
				transition: all 0.3s ease;
				top: 50%;
				right: 45%;
			}

			&:hover {
				&:before {
					width: 20px;
					right: 20px;
				}

				&:after {
					right: 20px;
				}
			}
		}
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		.mob {
			display: inline-block;
			position: absolute;
			top: 9.3rem;
			left: 0;
		}

		.des {
			display: none;
		}

		.hero {
			min-width: 100px;
		}

		.videoTag {
			opacity: 0.4;
			margin-left: -10px;
			margin-top: 0px;
			max-width: 100%;
		}

		.top-section {
			position: relative;
			margin-top: -15px;
			
			h1 {
				font-size: 3rem;
				line-height: 3rem;
				white-space: nowrap;
				margin-bottom: 10px;
			}
			h2 {
				font-size: 1rem;
				white-space: nowrap;
			}
		}
	}
`;
