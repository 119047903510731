import styled from "styled-components";
import { constants } from "../../../constants/constants";

export const LandingWrapper = styled.div`
	min-height: 300vh;
	width: 100%;
	max-width: 1400px;
	margin: 0 auto;
	margin-bottom: 3rem;

	.landing-layout {
		width: 100%;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		position: sticky;
		position: -webkit-sticky;
		top: 0;
		padding: 0 1rem;
		div {
			position: relative;
			width: 100%;

			.intro-text,
			.outro-text {
				width: max-content;
				text-align: center;
				font-size: 4.2rem;
				font-weight: 600;
				text-align: center;
				margin: 0 auto;
			}

			.description {
				position: absolute;
				bottom: -15.5rem;

				p {
					text-align: center;
					font-size: 1.2rem;
					transition: opacity 0.6s, transform 0.6s,
						-webkit-transform 0.6s, -moz-transform 0.6s;

					display: ${function ({ scrollPercent }) {
						if (scrollPercent > 94) return "block";
						else return "none";
					}};
				}

				span {
					opacity: ${function ({ scrollPercent }) {
						if (scrollPercent > 94) return 1;
						else return 0;
					}};

					transition: opacity 0.6s, transform 0.6s,
						-webkit-transform 0.6s, -moz-transform 0.6s;
					width: 70px;
					height: 70px;
					border: 1px solid rgba(250, 250, 250, 0.5);
					border-radius: 40px;
					cursor: pointer;
					transition: all linear 0.4s;
					transform: rotateZ(90deg);
					margin: 0 auto;
					margin-top: 3.5rem;
					display: block;

					&:before {
						width: 56px;
						content: "";
						height: 2px;
						background: white;
						position: absolute;
						margin-top: -1px;
						right: 50%;
						top: 50%;
						transition: all 0.3s ease;
					}

					&:after {
						content: "";
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 5px 0 5px 5px;
						border-color: transparent transparent transparent white;
						position: absolute;
						margin-top: -5px;
						transition: all 0.3s ease;
						top: 50%;
						right: 45%;
					}

					&:hover {
						&:before {
							width: 25px;
							right: 25px;
						}

						&:after {
							right: 20px;
						}
					}
				}
			}

			.intro-text {
				background: -webkit-gradient(
					linear,
					left top,
					right top,
					from(#ce55b0),
					color-stop(12%, #f34dac),
					color-stop(27%, #f48e66),
					color-stop(38%, #f0b832),
					color-stop(54%, #b2ce37),
					color-stop(70%, #63d7af),
					color-stop(84%, #1cc2df),
					color-stop(94%, #0580e6),
					to(#ce55b0)
				);
				background: -webkit-linear-gradient(
					left,
					#ce55b0,
					#f34dac 12%,
					#f48e66 27%,
					#f0b832 38%,
					#b2ce37 54%,
					#63d7af 70%,
					#1cc2df 84%,
					#0580e6 94%,
					#ce55b0
				);
				background: -moz-linear-gradient(
					left,
					#ce55b0 0,
					#f34dac 12%,
					#f48e66 27%,
					#f0b832 38%,
					#b2ce37 54%,
					#63d7af 70%,
					#1cc2df 84%,
					#0580e6 94%,
					#ce55b0 100%
				);
				background: linear-gradient(
					90deg,
					#ce55b0,
					#f34dac 12%,
					#f48e66 27%,
					#f0b832 38%,
					#b2ce37 54%,
					#63d7af 70%,
					#1cc2df 84%,
					#0580e6 94%,
					#ce55b0
				);
				background-size: 1000px;
				background-position: center;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				transition: opacity 0.6s, transform 0.6s, -webkit-transform 0.6s,
					-moz-transform 0.6s;

				display: ${function ({ scrollPercent }) {
					if (scrollPercent < 50) return "block";
					else return "none";
				}};

				opacity: ${function ({ scrollPercent }) {
					if (scrollPercent < 2.5) return 0;
					else if (scrollPercent < 10) return 0.3;
					else if (scrollPercent >= 25 && scrollPercent <= 50)
						return (50 - scrollPercent) / 10;
					else if (scrollPercent > 50) return 0;
					else return 0.3 + scrollPercent / 100;
				}};

				transform: scale(
					${function ({ scrollPercent }) {
						if (scrollPercent > 50) return 0;
						else if (scrollPercent >= 25) return 1;
						else
							return (50 - scrollPercent) / 10 + 2 > 1
								? (50 - scrollPercent) / 10 + 2
								: 1;
					}}
				);

				background: red;
			}

			.outro-text {
				background: -webkit-gradient(
					linear,
					left top,
					right top,
					from(#ce55b0),
					color-stop(12%, #f34dac),
					color-stop(27%, #f48e66),
					color-stop(38%, #f0b832),
					color-stop(54%, #b2ce37),
					color-stop(70%, #63d7af),
					color-stop(84%, #1cc2df),
					color-stop(94%, #0580e6),
					to(#ce55b0)
				);
				background: -webkit-linear-gradient(
					left,
					#ce55b0,
					#f34dac 12%,
					#f48e66 27%,
					#f0b832 38%,
					#b2ce37 54%,
					#63d7af 70%,
					#1cc2df 84%,
					#0580e6 94%,
					#ce55b0
				);
				background: -moz-linear-gradient(
					left,
					#ce55b0 0,
					#f34dac 12%,
					#f48e66 27%,
					#f0b832 38%,
					#b2ce37 54%,
					#63d7af 70%,
					#1cc2df 84%,
					#0580e6 94%,
					#ce55b0 100%
				);
				background: linear-gradient(
					90deg,
					#ce55b0,
					#f34dac 12%,
					#f48e66 27%,
					#f0b832 38%,
					#b2ce37 54%,
					#63d7af 70%,
					#1cc2df 84%,
					#0580e6 94%,
					#ce55b0
				);
				background-size: 1000px;
				background-position: center;
				background-clip: text;
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
				transition: opacity 0.6s, transform 0.6s, -webkit-transform 0.6s,
					-moz-transform 0.6s;

				display: ${function ({ scrollPercent }) {
					if (scrollPercent > 30) return "block";
					else return "none";
				}};

				opacity: ${function ({ scrollPercent }) {
					if (scrollPercent < 60) return 0;
					else if (scrollPercent < 70) return 0.3;
					else return 0.2 + scrollPercent / 100;
				}};

				transform: scale(
					${function ({ scrollPercent }) {
						if (scrollPercent < 50) return 0;
						else if (scrollPercent > 50 && scrollPercent < 95)
							return (80 - scrollPercent) / 10 + 2 > 1
								? (80 - scrollPercent) / 10 + 2
								: 1;
						else if (scrollPercent >= 80) return 1;
					}}
				);
			}
		}
	}
`;

export const SectionParent = styled.div`
	height: 250vh;
	scroll-behavior: smooth;
	padding: 0 10px;

	.firstContainer,
	.secondContainer {
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		/* scroll-snap-align: start; */
	}

	span {
		transition: opacity 0.6s, transform 0.6s, -webkit-transform 0.6s,
			-moz-transform 0.6s;
		width: 55px;
		height: 55px;
		border: 1px solid rgba(250, 250, 250, 0.5);
		border-radius: 40px;
		cursor: pointer;
		transition: all linear 0.4s;
		transform: rotateZ(90deg);
		margin-top: 3.5rem;
		display: block;

		&:before {
			width: 45px;
			content: "";
			height: 2px;
			background: white;
			position: absolute;
			margin-top: -1px;
			right: 50%;
			top: 50%;
			transition: all 0.3s ease;
		}

		&:after {
			content: "";
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 5px 0 5px 5px;
			border-color: transparent transparent transparent white;
			position: absolute;
			margin-top: -5px;
			transition: all 0.3s ease;
			top: 50%;
			right: 45%;
		}

		&:hover {
			&:before {
				width: 20px;
				right: 20px;
			}

			&:after {
				right: 20px;
			}
		}
	}

	.intro-text,
	.outro-text {
		width: max-content;
		text-align: center;
		font-size: 4.2rem;
		font-weight: 600;
		text-align: center;
		margin: 0 auto;

		background: -webkit-gradient(
			linear,
			left top,
			right top,
			from(#ce55b0),
			color-stop(12%, #f34dac),
			color-stop(27%, #f48e66),
			color-stop(38%, #f0b832),
			color-stop(54%, #b2ce37),
			color-stop(70%, #63d7af),
			color-stop(84%, #1cc2df),
			color-stop(94%, #0580e6),
			to(#ce55b0)
		);
		background: -webkit-linear-gradient(
			left,
			#ce55b0,
			#f34dac 12%,
			#f48e66 27%,
			#f0b832 38%,
			#b2ce37 54%,
			#63d7af 70%,
			#1cc2df 84%,
			#0580e6 94%,
			#ce55b0
		);
		background: -moz-linear-gradient(
			left,
			#ce55b0 0,
			#f34dac 12%,
			#f48e66 27%,
			#f0b832 38%,
			#b2ce37 54%,
			#63d7af 70%,
			#1cc2df 84%,
			#0580e6 94%,
			#ce55b0 100%
		);
		background: linear-gradient(
			90deg,
			#ce55b0,
			#f34dac 12%,
			#f48e66 27%,
			#f0b832 38%,
			#b2ce37 54%,
			#63d7af 70%,
			#1cc2df 84%,
			#0580e6 94%,
			#ce55b0
		);
		background-size: 1000px;
		background-position: center;
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transition: opacity 0.6s, transform 0.6s, -webkit-transform 0.6s,
			-moz-transform 0.6s;

		@media (max-width: 768px) {
			font-size: 3rem;
			width: 100%;
		}

		img {
			position: absolute;
			z-index: -1;
			opacity: 0.25;
		}
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		p {
			font-size: 2rem;
		}
	}
`;
