import styled from "styled-components";
import { Flex } from "@chakra-ui/react";
import { constants } from "../../../constants/constants";

export const FooterLayout = styled(Flex)`
	padding: 3rem 0;
	margin: 0;
	width: 1400px;
	max-width: unset;
	flex-direction: column;
	align-items: center;
	background: ${constants.theme.background};

	.marquee1 {
		.marquee {
			min-width: fit-content;
		}
	}

	@media (max-width: 1400px) {
		width: 100vw;
	}

	.logo {
		width: 3rem;
	}

	.footerWrapper {
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 1400px;
		margin: 3rem 0 0 0;
		align-items: center;
	}
	.tredmark{
		font-size: 14px;
	}

	.footerLinks {
		ul {
			list-style-type: none;

			li {
				margin: 20px 0;
			}
		}
	}

	.socialsWrapper {
		display: flex;

		.socialItem {
			margin: 0 1rem;

			img {
				margin-bottom: 5px;
			}
		}
	}

	.footerDetailsWrapper {
		display: flex;

		.footerText {
			padding-right: 10px;
			margin-right: 10px;
			line-height: 16px;
			font-size: 14px;

			&:not(&:last-child) {
				border-right: 1px solid white;
			}
		}
	}

	.link {
		margin: 0 1rem 1rem 0.5rem;
		width: 2.5rem;
		height: 2.5rem;
		background: #fff;
		border-radius: 0.5rem;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		align-items: flex-end;

		.footerWrapper {
			margin: 1rem 0;
			flex-direction: column;
			margin-bottom: 1.2rem;

			.tredmark{
				margin: 1.2rem 0 0;
				font-size: 14px;
			}
		}

		.social-grp {
			margin: 3rem 0 1rem 0;
		}
	}
`;

export const ThinkLayout = styled.div`
	display: flex;
	margin-bottom: 1.5rem;
	justify-content: center;
	align-items: center;
	animation: 17.856s linear 0s infinite normal none running marquee;
	overflow-x: auto;

	&::-webkit-scrollbar {
		display: none;
	}

	.think-text {
		font-size: 2.5rem;
		font-weight: 600;
		margin: 0 1.5rem;
		display: flex;
		min-width: max-content;
	}
`;

export const GradientText = styled.span`
	background-image: linear-gradient(
		to right,
		#${({ gradientColors }) => gradientColors[0]},
		#${({ gradientColors }) => gradientColors[1]}
	);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
`;

export const MobileOverlayLayout = styled.div`
	height: 60px;
	width: 95%;
	/* display: flex; */
	/* align-items: end; */
	margin-bottom: 10px;
	position: fixed;
	bottom: 0;

	.overlay {
		height: 60px;
		width: 100%;
		/* background: red; */
		background: rgba(15, 15, 15, 0.9);
		display: flex;
		flex-direction: row;
		align-items: center;
		border-radius: 13px;
		box-shadow: rgba(15, 15, 15, 0.24) 0px 3px 8px;

		div {
			width: 50%;
			text-align: center;
			justify-content: center;

			.thzTokenbtn {
				background: linear-gradient(to right bottom, #d445ba, #e7a650);
				padding: 8px 1.5rem;
				border-radius: 5px;
				font-weight: 600;
				color: white;

				&:hover {
					background: linear-gradient(
						to right bottom,
						#40c9ff,
						#e81cff
					);
				}
			}

			.PSBtn {
				color: #d02896;
				border: 1px solid #d02896;
				padding: 8px 1.5rem;
				border-radius: 20px;
				background: transparent;
				&:hover {
					background: #d02896;
					color: #fff;
				}
			}
		}
	}
`;
