import React from "react";
import { MainBox, Card, Box } from "./styles";

const Roadmap = ({ data }) => {
	return (
		<>
			<div>
				<h2 className="sub-heading-1 text-center">How we started</h2>
				<p className="grad-head">Roadmap</p>
				<p className="text-center">Learn all about it here.</p>
			</div>

			<MainBox>
				{data.map((item, i) => {
					let time = 1000 + i * 250;
					return (
						<Card data-aos="fade-up" data-aos-duration={`${time}`}>
							<Box>
								<ul>
									{item.content.map((para) => (
										<li>{para}</li>
									))}
								</ul>
							</Box>
							<p>{item.header}</p>
						</Card>
					);
				})}
			</MainBox>
		</>
	);
};

export default Roadmap;
