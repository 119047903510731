import React from "react";
import styled from "styled-components";
import { ResponsivePie } from "@nivo/pie";
import { data } from "./PieChartData";
import { constants } from "../../../constants/constants";
import { Table } from "./styles";
import { useAos } from "../../../hooks/useAos";

export default function PieChart() {
	useAos(1000);

	return (
		<>
			<Div data-aos="fade-up" data-aos-duration="2000">
				<Parent>
					<Header className="text-center"></Header>
					{/* <div className="row"> */}
					{/* <div className="col-lg-9 col-md-6 col-12"> */}
					<SubParent>
						<ResponsivePie
							data={data}
							margin={{
								top: 30,
								right: 120,
								bottom: 180,
								left: 120,
							}}
							innerRadius={0.5}
							padAngle={1}
							cornerRadius={5}
							activeOuterRadiusOffset={8}
							borderWidth={1}
							borderColor={{
								from: "color",
								modifiers: [["darker", 0.2]],
							}}
							arcLinkLabel="id"
							arcLinkLabelsSkipAngle={10}
							arcLinkLabelsTextColor="#fff"
							arcLinkLabelsThickness={2}
							arcLinkLabelsColor={{ from: "color" }}
							arcLabel="label"
							arcLabelsSkipAngle={10}
							arcLabelsTextColor={{
								from: "color",
								modifiers: [["darker", 2]],
							}}
							isInteractive={false}
							defs={[
								{
									id: "dots",
									type: "patternDots",
									background: "inherit",
									color: "rgba(255, 255, 255, 0.3)",
									size: 4,
									padding: 1,
									stagger: true,
								},
							]}
							fill={[
								{
									match: {
										label: "10%",
									},
									id: "dots",
								},
								{
									match: {
										label: "4%",
									},
									id: "dots",
								},
								{
									match: {
										label: "2%",
									},
									id: "dots",
								},
							]}
						/>
					</SubParent>
				</Parent>

				<Table>
					<div>
						<ul className="chart-table">
							<div className="table-logo">
								<img
									src="/images/logos/img3.svg"
									width="40px"
									alt="logo"
								/>
								&nbsp;&nbsp;
								<p className="mb-0">Thoughtz</p>
							</div>

							<li>
								<div className="left-li">
									<span className="span2">8%</span>
									<p>Seed</p>
								</div>
								<p className="right-li">7,20,00,000</p>
							</li>

							<li>
								<div className="left-li">
									<span className="span3">14%</span>
									<p>Private</p>
								</div>
								<p className="right-li">12,60,00,000</p>
							</li>

							<li>
								<div className="left-li">
									<span className="span1">2%</span>
									<p>Public</p>
								</div>
								<p className="right-li">1,80,00,000</p>
							</li>

							<li>
								<div className="left-li">
									<span className="span1">8%</span>
									<p>Marketing</p>
								</div>
								<p className="right-li">7,20,00,000</p>
							</li>

							<li>
								<div className="left-li">
									<span className="span5">24%</span>
									<p>Rewards pool</p>
								</div>
								<p className="right-li">21,60,00,000</p>
							</li>

							<li>
								<div className="left-li">
									<span className="span5">20%</span>
									<p>Development</p>
								</div>
								<p className="right-li">18,00,00,000</p>
							</li>

							<li>
								<div className="left-li">
									<span className="span6">12%</span>
									<p>Team</p>
								</div>
								<p className="right-li">10,80,00,000</p>
							</li>

							<li>
								<div className="left-li">
									<span className="span5">2%</span>
									<p>Advisors</p>
								</div>
								<p className="right-li">1,80,00,000</p>
							</li>

							<li>
								<div className="left-li">
									<span className="span7">10%</span>
									<p>Treasury</p>
								</div>
								<p className="right-li">90,000,000</p>
							</li>
						</ul>
					</div>
				</Table>
			</Div>
		</>
	);
}

const Div = styled.div`
	margin: 0 auto;
	width: 90%;
	display: flex;
	justify-content: space-between;
	align-items: start;

	@media (max-width: ${constants.breakpoints.desktop}) {
		flex-direction: column;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		width: 100%;
	}
`;

const Parent = styled.div`
	margin-bottom: 5rem;
	width: 125%;

	@media (max-width: ${constants.breakpoints.mobile}) {
		margin-bottom: 0;
		width: 100%;
	}
`;

const Header = styled.h1`
	font-size: 4rem;
	margin-bottom: 5%;
	color: rgba(242, 43, 179, 1);
	font-weight: 800;
`;

const SubParent = styled.div`
	height: 70vh;
	width: 100%;
`;
