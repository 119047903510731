export const tableData = [
	{
		label: "Seed",
		percent: "8%",
		tokens: "7,20,00,000",
		tokenPrice: "$0.015",
		vesting:
			"7.5% unlock at TGE, 1 month cliff, followed by monthly linear release over 12 months (Total vesting 13 months)",
	},
	{
		label: "Private",
		percent: "14%",
		tokens: "12,60,00,000",
		tokenPrice: "$0.025",
		vesting:
			"10% unlock at TGE, 1 month cliff, followed by monthly linear release over 12 months (Total vesting 13 months)",
	},
	{
		label: "Public",
		percent: "2%",
		tokens: "1,80,00,000",
		tokenPrice: "$0.05",
		vesting:
			"25% unlock at TGE, followed by monthly linear release over 4 months (Total vesting 4 months)",
	},
	{
		label: "Marketing",
		percent: "8%",
		tokens: "7,20,00,000",
		tokenPrice: "N/A",
		vesting: "2% monthly release from TGE for first 12 months, followed by monthly linear release over 18 months (Total vesting 30 months)",
	},
	{
		label: "Rewards pool",
		percent: "24%",
		tokens: "21,60,00,000",
		tokenPrice: "N/A",
		vesting:
			"Unlock when required (Incentives and rewards to the users, community and early adopters)",
	},
	{
		label: "Development",
		percent: "20%",
		tokens: "18,00,00,000",
		tokenPrice: "N/A",
		vesting:
			"6 months cliff, followed by monthly linear release over 36 months (Total vesting 42 months)",
	},
	{
		label: "Team",
		percent: "12%",
		tokens: "10,80,00,000",
		tokenPrice: "N/A",
		vesting:
			"18 months cliff, followed by monthly linear release over 30 months (Total vesting 48 months)",
	},
	{
		label: "Advisors",
		percent: "2%",
		tokens: "1,80,00,000",
		tokenPrice: "N/A",
		vesting:
			"2% monthly release from TGE for first 12 months, followed by monthly release release over 18 months (Total vesting 30 months)",
	},
	{
		label: "Treasury",
		percent: "10%",
		tokens: "90,000,000",
		tokenPrice: "N/A",
		vesting:
			"Unlock when required (Exchange listings, legal and in the future for other operational costs)",
	},
];
