import styled from "styled-components";
import { constants } from "../../constants/constants";

export const TermsLayout = styled.section`
	.heading {
		font-size: 5rem;
		font-weight: 800;
		margin-top: 10rem;
		background: url("/images/background.jpg");
		background-size: cover;
		background-position: center;
		-webkit-text-fill-color: transparent;
		-webkit-background-clip: text;
		background-clip: text;
		margin-bottom: 2.5rem;
	}

	@media (max-width: ${constants.breakpoints.mobile}) {
		.heading {
			font-size: 2.7rem;
			margin-bottom: 1.5rem;
		}
	}

	font-size: 14px;
`;
