import { useAos } from "../../../hooks/useAos";
import { contentArray } from "./data";
import { DescriptionLayout, RowitemLayout, Paragraph } from "./styles";

const Rowitem = ({ svg, number, heading, description }) => {
	useAos(1000);
	return (
		<RowitemLayout data-aos="fade-up">
			{svg}
			<p className="heading">{heading}</p>
			<p className="description">{description}</p>
		</RowitemLayout>
	);
};

const Heading = () => {
	useAos(1000);
	return (
		<RowitemLayout data-aos="fade-up">
			<Paragraph>Features Of ICO</Paragraph>
			<Paragraph style={{ marginTop: "0" }}>Platform</Paragraph>
		</RowitemLayout>
	);
};

export const Description = () => {
	return (
		<DescriptionLayout>
			{/* <div className= "section-label" style= {{ margin: "3rem 0 5rem 0" }}>Special Case</div> */}
			<Heading />
			<div className="cardRow">
				{contentArray.map((item, index) => (
					<Rowitem
						key={index}
						svg={item.svg}
						number={item.number}
						heading={item.heading}
						description={item.description}
					/>
				))}
			</div>
		</DescriptionLayout>
	);
};
