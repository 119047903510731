import { useEffect, useRef, useState } from "react";
import {
	DivWithBackground,
	OverviewLayout,
	OverviewWrapper,
	StickeyCard,
} from "./styles";
import jquery from "jquery";
import { cardData } from "./data";
import { useMediaQuery } from "react-responsive";
import { constants } from "../../../constants/constants";
import { useAos } from "../../../hooks/useAos";

function getShowDetails(index, scrollPercent) {
	switch (index) {
		case 0:
			return scrollPercent <= 33.33;
		case 1:
			return scrollPercent >= 33.33 && scrollPercent <= 66.66;
		default:
			return scrollPercent >= 66.66;
	}
}

const Overview = function () {
	const ref = useRef(null);
	const [scrollPercent, setScrollPercent] = useState(0);
	const [imageURL, setImageURL] = useState("/images/Screen1.svg");

	const isMobile = useMediaQuery({
		query: `(max-width: ${constants.breakpoints.ipad})`,
	});

	useAos(1000);

	useEffect(
		function () {
			if (!isMobile && ref.current) {
				jquery(window).on("scroll", function () {
					const windowScrollPosition = jquery(this).scrollTop(),
						childOffset = jquery(".overview-wrapper").offset()?.top,
						limiter = ref.current?.clientHeight / 2;

					if (
						windowScrollPosition >= childOffset &&
						windowScrollPosition <= limiter + childOffset
					) {
						const percent =
							((windowScrollPosition - childOffset) / limiter) *
							100;
						setScrollPercent(percent);
					}
				});
			}
		},
		[ref, isMobile]
	);

	useEffect(() => {
		if (scrollPercent < 33.33 && imageURL !== "/images/mockup/Screen1.png")
			setImageURL("/images/mockup/Screen1.png");
		else if (
			scrollPercent >= 33.33 &&
			scrollPercent < 66.66 &&
			imageURL !== "/images/mockup/Screen2.png"
		)
			setImageURL("/images/mockup/Screen2.png");
		else if (
			scrollPercent >= 66.66 &&
			scrollPercent !== "/images/mockup/Screen3.png"
		)
			setImageURL("/images/mockup/Screen3.png");
	}, [scrollPercent]);

	return (
		<OverviewWrapper
			data-aos="fade-up"
			className="overview-wrapper"
			ref={ref}
		>
			<div data-aos="fade-up" className="heading mx-3">
				Key Highlights
			</div>
			<OverviewLayout className="overview">
				<div data-aos="fade-up imgBg">
					<img className="image" src={imageURL} alt="mockupScreen" />
				</div>
				<div data-aos="fade-up">
					{cardData.map((item, index) => (
						<StickeyCard
							key={index}
							showDetails={getShowDetails(index, scrollPercent)}
							index={index}
						>
							{isMobile && (
								<div
									data-aos="fade-up"
									className="d-flex justify-content-center"
								>
									<img
										className="image1"
										src={item.uri}
										alt="mockupScreen"
									/>
								</div>
							)}
							<h2>{item.heading}</h2>
							{isMobile && <p>{item.paragraph}</p>}
							{!isMobile &&
								getShowDetails(index, scrollPercent) && (
									<p>{item.paragraph}</p>
								)}
						</StickeyCard>
					))}
				</div>
			</OverviewLayout>
		</OverviewWrapper>
	);
};

export default Overview;
{
	/* <DivWithBackground uri={item.uri} /> */
}
