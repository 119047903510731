import React, { useEffect, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import * as jquery from "jquery";

import { JobsLayout } from "./styles";
import { jobs, inputFields } from "./data";

import { BsArrowLeft } from "react-icons/bs";

//Components
import { Navbar } from "../../components/global/navbar/navbar";
import { Footer } from "../../components/home/footer/footer";

export default function Jobs() {
	const formRef = useRef();
	const headerRef = useRef();
	const history = useHistory();
	let isIphone =
		/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

	const [formData, setFormData] = useState({
		first_name: "",
		last_name: "",
		email: "",
		phone: "",
		linkedin: "",
	});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		function scrollAnimation() {
			const conTop = formRef.current.getBoundingClientRect().top;
			const headTop = headerRef.current.getBoundingClientRect().top;
			let screenHeight = window.innerHeight / 3;

			if (isIphone) {
				screenHeight = jquery(window).width() / 3;
			}

			headTop <= 90 && screenHeight < conTop
				? headerRef.current.classList.add("heading")
				: headerRef.current.classList.remove("heading");

			screenHeight >= conTop
				? headerRef.current.classList.add("none")
				: headerRef.current.classList.remove("none");
		}
		window.addEventListener("scroll", scrollAnimation);

		return () => window.removeEventListener("scroll", scrollAnimation);
	}, []);

	useEffect(() => {
		document.title = "Jobs | Thoughtz.ai";
	}, []);

	const query = new URLSearchParams(useLocation().search);
	const jobId = query.get("job_id");
	const { head, ...details } = jobs[jobId];

	const gotoPage = () => {
		const top = formRef.current.offsetTop - 65;
		window.scrollTo({
			top,
			left: 0,
			behavior: "smooth",
		});
	};

	const changeHandler = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const goBacktoCareer = () => {
		history.push("/career");
	};

	const emailValidation = (email) => {
		const regex =
			/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
		if (!email || regex.test(email) === false) {
			toast.error("Email invalid");
			setLoading(false);
			return false;
		}
		return true;
	};

	const handleSubmitJob = async (e) => {
		e.preventDefault();
		setLoading(true);
		const { first_name, last_name, email, phone, linkedin } = formData;
		if ((!first_name, !last_name, !email, !linkedin)) {
			toast.error("Please fill all the required fields");
			setLoading(false);
		} else {
			let emailRes = emailValidation(email);

			if (emailRes === false) return;

			let res = await axios.post(
				"https://us-central1-thoughtz-app.cloudfunctions.net/jobs",
				{
					first_name,
					last_name,
					email,
					phone,
					linkedin,
				}
			);

			if (res.status === 201) toast.success("Successfully Applied");
			else toast.error("There was an error");

			setFormData({
				first_name: "",
				last_name: "",
				email: "",
				phone: "",
				linkedin: "",
			});
		}
		setLoading(false);
	};

	return (
		<>
			<Navbar />
			<JobsLayout>
				<div className="jobs-main-container">
					<div ref={headerRef} className="job-header-main-container ">
						<div className="job-header-container">
							<div className="head d-flex align-items-center">
								<button
									className="d-sm-block d-none"
									onClick={goBacktoCareer}
								>
									<BsArrowLeft />
								</button>
								<h2 className="mx-sm-3">{head}</h2>
							</div>
							<div className="button">
								<button onClick={gotoPage}>Apply Now</button>
							</div>
						</div>
					</div>
					<div className="job-des-container">
						{Object.entries(details).map(
							([key, { head, content }]) => (
								<div className="content" key={key}>
									<h3>{head}</h3>
									{typeof content == "string" ? (
										<p>{content}</p>
									) : (
										<ul>
											{content.map((s, index) => (
												<li key={index}>{s}</li>
											))}
										</ul>
									)}
								</div>
							)
						)}
					</div>
					<div className="formContainer">
						<div ref={formRef} className="job-contact-form">
							<div className="title">
								<p
									style={{
										fontSize: "35px",
										marginBottom: "10px",
									}}
								>
									Apply
								</p>
								<p>{head}</p>
								<div className="my-md-5 my-3">OR</div>
								<p
									style={{
										fontSize: "35px",
										marginBottom: "10px",
									}}
								>
									Send email to
								</p>
								<p>
									<a
										href="mailto: jobs@thoughtz.ai"
										target="_blank"
									>
										jobs@thoughtz.ai
									</a>
								</p>
							</div>
							<form className="form">
								{inputFields.map(
									(
										{ type, name, required, label },
										index
									) => (
										<div
											className="input-fields"
											key={index}
										>
											<label>
												{label}
												{required ? (
													<span
														className="small text-muted"
														style={spanStyle}
													>
														*Required
													</span>
												) : (
													<span
														className="small text-muted"
														style={spanStyle}
													>
														(optional)
													</span>
												)}
											</label>
											<input
												type={type}
												name={name}
												value={formData[name]}
												required={required}
												onChange={(e) =>
													changeHandler(e)
												}
												autoComplete={"off"}
												id={
													type === "file"
														? "resume"
														: null
												}
											/>
											{type === "file" && (
												<label
													className="resume-lable-input"
													htmlFor="resume"
												>
													Upload
												</label>
											)}
											{type === "file" && (
												<span
													style={{
														fontSize: "13px",
													}}
												>
													{formData.resume}
												</span>
											)}
										</div>
									)
								)}
								<button
									type="button"
									onClick={handleSubmitJob}
									disabled={loading}
									className="submitButton"
								>
									{loading && (
										<span
											class="spinner-border spinner-border-sm me-2"
											role="status"
											aria-hidden="true"
										></span>
									)}
									Submit Application
								</button>
							</form>
						</div>
					</div>
				</div>
			</JobsLayout>
			<Toaster />
			<Footer />
		</>
	);
}

const spanStyle = {
	color: "var(--gray-color)",
	marginLeft: "10px",
};
